<template>
  <div v-if="isLoaded" class="mx-auto">
    <v-slide-x-transition hide-on-leave group>
      <BT-Blade-Items
        v-if="mode == 'pickup' && pickupID == null"
        :canExportCSV="false"
        canRefresh
        canSearchLocal
        key="1"
        label="Pickups"
        navigation="releases"
        :params="{ sortOrder: 'Ascending', filterBy: 'Unfulfilled,Pickups,Pending', includeDetails: false, properties: 'Buyer,ID,DueDepartureOn,ReleasedOn,DepartureLocation' }"
        :searchProps="['buyer.companyName']"
        showList
        :small="false"
        title="Pickups"
        toolbarClass="primary"
        transparent
        useServerPagination>
        <template v-slot:toolbar-right>
          <BT-Skin-Menu :small="false" />
        </template>
        <template v-slot:body="{ items }">
          <v-card class="transparent ma-0 pa-0 overflow-y-auto" style="height: calc(100vh - 96px)">
            <v-row no-gutters>
              <v-col v-for="(item, ind) in items" :key="ind">
                <v-card class="ma-1 pa-1 text-truncate" @click.stop="selectPickup(item)" min-width="150px">
                  <v-list-item dense>
                    <v-list-item-avatar>
                      <v-img :src="companyLogoURL(item.buyerID)" class="my-auto">
                          <template v-slot:placeholder><v-icon size="40" class="primary--text">mdi-account-outline</v-icon></template>
                      </v-img>
                    </v-list-item-avatar>
                    <v-list-item-content>
                      <v-list-item-title>{{ item.buyer.companyName }}</v-list-item-title>
                      <v-list-item-subtitle>{{ item.departureLocation | toFamiliarLocationLine }}</v-list-item-subtitle>
                    </v-list-item-content>
                    <v-list-item-action class="d-flex flex-column align-center justify-center">
                      <v-icon>mdi-calendar-blank</v-icon>
                      {{ item.dueDepartureOn | toFormat('ccc dd LLL') }}
                    </v-list-item-action>
                  </v-list-item>
                  <v-row class="faded-text--text mb-1" no-gutters>
                    <v-divider class="my-auto ml-4 mr-2" style="border-top: 2px dotted;" />
                    <span v-if="item.releasedOn != null" class="warning--text">Released</span>
                    <span v-else class="success--text">Pending</span>
                    <v-divider class="my-auto ml-2 mr-4" style="border-top: 2px dotted;" />
                  </v-row>
                </v-card>
              </v-col>
            </v-row>
          </v-card>
        </template>
      </BT-Blade-Items>
      <BT-Blade-Items
        v-else-if="mode == 'journey' && journeyID == null"
        @fetched="maybeShowMore"
        :canExportCSV="false"
        canRefresh
        canSearchLocal
        key="2"
        label="Journeys"
        navigation="journeys"
        :params="{ filterBy: 'Ready', departureLocationID: selectedLocationID(), driverID: driverJourneysOnly ? $BlitzIt.auth.session.userID : null }"
        :refreshToggle="journeyRefreshToggle"
        :searchProps="['journeyName']"
        :showFilters="false"
        showList
        :small="false"
        toolbarClass="primary"
        title="Journeys"
        transparent>
        <template v-slot:toolbar-right>
          <BT-Skin-Menu :small="false" />
        </template>
        <template v-slot:actionsRight>
          <v-spacer />
          <v-btn text @click="toggleJourneys">
            <v-icon left>mdi-filter</v-icon>
            {{ driverJourneysOnly ? 'All Journeys' : 'My Journeys' }}
          </v-btn>
        </template>
        <template v-slot:body="{ items }">
          <v-card class="transparent ma-0 pa-0 overflow-y-auto" style="height: calc(100vh - 96px)">
            <v-row no-gutters>
              <v-col v-for="(item, ind) in items" :key="ind">
                <v-card class="ma-1 pa-0 text-truncate" @click.stop="selectJourney(item)" min-width="250px">
                  <v-card-title>{{ item.journeyName }}</v-card-title>
                  <v-card-subtitle>{{ item.startLocation | toFamiliarLocationLine }}</v-card-subtitle>
                    <v-row dense>
                      <v-col cols="4" class="d-flex flex-column align-center justify-center text-wrap text-center">
                        <v-icon large>mdi-steering</v-icon>
                        <BT-Entity
                            navigation="public-users"
                            inline
                            :itemValue="item.driverUserID"
                            single
                            textFilter="toUserLine"
                            useLocalCache>
                            <template v-slot:alternate>(No Driver)</template>
                        </BT-Entity>
                      </v-col>
                      <v-col cols="4" class="d-flex flex-column align-center justify-center">
                        <v-icon large>mdi-map-marker-multiple</v-icon>
                        {{ item.destinationCount | toDisplayNumber }} Destination{{ item.destinationCount == 1 ? "" : "s" }}
                      </v-col>
                      <v-col cols="4" class="d-flex flex-column align-center justify-center">
                        <v-icon large>mdi-calendar-blank</v-icon>
                        {{ item.dueStartOn | toFormat('ccc dd LLL') }}
                      </v-col>
                    </v-row>
                    <v-row class="faded-text--text mb-1" no-gutters>
                      <v-divider class="my-auto ml-4 mr-2" style="border-top: 2px dotted;" />
                      <span v-if="item.startedOn != null" class="warning--text">In Progress</span>
                      <span v-else-if="item.isReady" class="success--text">Ready</span>
                      <v-divider class="my-auto ml-2 mr4" style="border-top: 2px dotted;" />
                    </v-row>
                </v-card>
              </v-col>
            </v-row>
          </v-card>
        </template>
      </BT-Blade-Items>
      <v-card v-else-if="mode == 'journey' || mode == 'pickup'" class="mx-auto" key="3">
        <v-toolbar
          class="primary"
          dark
          dense
          :extension-height="(showAddress && destinationLocation != null) ? 75 : 0"
          flat>
          <v-btn
            icon
            title="Back"
            @click="tryNavBack">
            <v-icon>mdi-arrow-left</v-icon>
          </v-btn>
          <v-toolbar-title @click="tryToggleAddress">{{ title }}
            <v-icon v-if="isPerformStage && destinationLocation != null" right>{{ showAddress ? 'mdi-chevron-up' : 'mdi-chevron-down' }}</v-icon>
          </v-toolbar-title>
          <v-spacer />
          <v-slide-y-transition hide-on-leave group>
            <v-menu
                :close-on-content-click="false"
                offset-x
                left
                key="3">
                <template v-slot:activator="{ on, attrs }">
                    <v-btn 
                        icon
                        v-bind="attrs"
                        v-on="on"
                        title="Settings">
                        <v-icon>mdi-cog</v-icon>
                    </v-btn>
                </template>
                <v-list>
                  <v-list-item :disabled="journey == null" @click="sortJourney">
                    <v-list-item-icon>
                      <v-icon small>mdi-sort-numeric-ascending</v-icon>
                    </v-list-item-icon>
                    <v-list-item-content>
                      <v-list-item-title>Auto Sort Route</v-list-item-title>
                    </v-list-item-content>
                  </v-list-item>
                  <v-list-item :disabled="journey == null" @click="openInMaps(journey)">
                    <v-list-item-icon>
                      <v-icon small>mdi-google-maps</v-icon>
                    </v-list-item-icon>
                    <v-list-item-content>
                      <v-list-item-title>Google Maps</v-list-item-title>
                    </v-list-item-content>
                  </v-list-item>
                  <v-divider />
                  <v-list-item @click="breakdownToggle = !breakdownToggle">
                      <v-list-item-icon>
                          <v-icon>mdi-counter</v-icon>
                      </v-list-item-icon>
                      <v-list-item-content>
                          <v-list-item-title>Quantity Breakdowns</v-list-item-title>
                          <BT-Select-List-Dialog
                              @ok="(list) => { measurements = list }"
                              hideButton
                              itemText="measurementName"
                              itemValue="id"
                              label="Measurements"
                              multiple
                              navigation="measurements"
                              :showToggle="breakdownToggle"
                              width="350" />
                      </v-list-item-content>
                  </v-list-item>
                  <v-divider />
                </v-list>
            </v-menu>
          </v-slide-y-transition>
          
          <template v-slot:extension>
            <v-list-item v-if="showAddress && destinationLocation != null" key="3">
              <v-list-item-content >
                <strong class="text-truncate">{{ title }}</strong>
                <div class="text-truncate">{{ destinationLocation | toLocationLineOne }}</div>
                <div class="text-truncate">{{ destinationLocation | toLocationLineTwo }}</div>
              </v-list-item-content>
              <v-list-item-action>
                <v-menu v-if="performStage == 0 && isLengthyArray(dFilteredMovements) && dFilteredMovements.length == 1">
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn 
                      icon 
                      v-bind="attrs" 
                      v-on="on"
                      title="Print"><v-icon>mdi-printer</v-icon></v-btn>
                  </template>
                  <v-list dense>
                    <v-list-item @click="printInvoice">
                      <v-list-item-content>Invoice</v-list-item-content>
                    </v-list-item>
                    <v-list-item @click="showPODToggle = !showPODToggle">
                      <v-list-item-content>Proof Of Delivery</v-list-item-content>
                    </v-list-item>
                  </v-list>
                </v-menu>
                
                <BT-Print
                  :getFileName="item => `Invoice #${item.invoice.customerOrderNumber}.pdf`"
                  hide
                  :onPullSuccessAsync="pullForInvoice"
                  :showToggle="showPrintToggle">
                  <template v-slot="{ item, strategy }">
                    <v-container v-if="item != null" class="pdf-page">
                      <v-row>
                        <v-col cols="8">
                          <div style="height: 25px;" />
                          <v-img v-if="strategy != 'jspdf'" class="pdf-logo" :src="item.logoImageData" max-width="100px" max-height="100px" />
                          <v-list-item three-line>
                              <v-list-item-content>
                                  <v-list-item-subtitle>BILLED BY</v-list-item-subtitle>
                                  <v-list-item-title><v-icon left small>mdi-account</v-icon><strong>{{ item.consignment.seller.companyName }}</strong>
                                  </v-list-item-title>
                                  <v-list-item-subtitle v-if="$BlitzIt.auth.session.company.primaryEmail != null" >
                                      <v-icon left small>mdi-email</v-icon>{{ $BlitzIt.auth.session.company.primaryEmail }}
                                  </v-list-item-subtitle>
                                  <v-list-item-subtitle v-if="$BlitzIt.auth.session.company.phoneNumber != null">
                                      <v-icon left small>mdi-phone</v-icon>{{ $BlitzIt.auth.session.company.phoneNumber }}
                                  </v-list-item-subtitle>
                              </v-list-item-content>
                          </v-list-item>
                          <v-list-item class="mt-5">
                              <v-list-item-content>
                                  <v-list-item-subtitle>
                                      <v-icon left small>mdi-arrow-right-bottom</v-icon>BILLED TO
                                  </v-list-item-subtitle>
                                  <v-list-item-title>
                                      <v-icon left small>mdi-account</v-icon><strong>{{ item.consignment.buyer.companyName }}</strong>
                                  </v-list-item-title>
                              </v-list-item-content>
                          </v-list-item>
                          <div v-if="item.delivery != null" class="text-caption">
                              <span class="mr-5">Signed By: {{ item.delivery.receiverName }}</span>
                              <span class="mx-5">Temp: {{ item.delivery.tempOnDelivery }}</span>
                          </div>
                          <BT-Signature
                              v-if="item.delivery != null"
                              color="black"
                              height="150px"
                              :value="item.delivery.proofOfDelivery"
                              backgroundColor="white" />
                      </v-col>
                      <v-col cols="4">
                        <v-list-item>
                            <v-list-item-content>
                                <h2>Invoice</h2>
                            </v-list-item-content>
                        </v-list-item>
                        <v-list-item dense>
                            <v-list-item-content>
                                <v-list-item-subtitle>Customer Order #</v-list-item-subtitle>
                                <v-list-item-title>{{ item.invoice.customerOrderNumber }}</v-list-item-title>
                            </v-list-item-content>
                        </v-list-item>
                        <v-list-item dense>
                            <v-list-item-content>
                                <v-list-item-subtitle>Purchase Order #</v-list-item-subtitle>
                                <v-list-item-title>{{ item.invoice.purchaseOrderNumber }}</v-list-item-title>
                            </v-list-item-content>
                        </v-list-item>
                        <v-list-item dense>
                            <v-list-item-content>
                                <v-list-item-subtitle>Issued On</v-list-item-subtitle>
                                <v-list-item-title>{{ item.invoice.issuedOn | toShortDate }}</v-list-item-title>
                            </v-list-item-content>
                        </v-list-item>
                        <v-list-item dense>
                            <v-list-item-content>
                                <v-list-item-subtitle>Due On</v-list-item-subtitle>
                                <v-list-item-title>{{ item.invoice.dueOn | toShortDate }}</v-list-item-title>
                            </v-list-item-content>
                        </v-list-item>
                        <BT-Entity
                            v-if="item.delivery != null && item.delivery.isPickup"
                            :itemValue="item.delivery.departureLocationID"
                            navigation="public-locations"
                            single
                            useLocalCache>
                            <template #default="{ item }">
                                <BT-Field-Address
                                    label="Pickup Up At"
                                    :value="item" />
                            </template>
                        </BT-Entity>
                        <BT-Entity
                            v-else-if="item.delivery != null && !item.delivery.isPickup"
                            :itemValue="item.delivery.destinationLocationID"
                            navigation="public-locations"
                            single
                            useLocalCache>
                            <template #default="{ item }">
                                <BT-Field-Address
                                    label="Delivered To"
                                    :value="item" />
                            </template>
                        </BT-Entity>
                        <v-list-item v-if="item.delivery != null && !item.delivery.isPickup" dense>
                            <v-list-item-content>
                                <v-list-item-title>Delivered On</v-list-item-title>
                                <v-list-item-subtitle>{{ item.delivery.deliveredOn | toShortDateAndTime }}</v-list-item-subtitle>
                            </v-list-item-content>
                        </v-list-item>
                        <v-list-item v-else-if="item.delivery != null && item.delivery.isPickup" dense>
                            <v-list-item-content>
                                <v-list-item-title>Picked Up On</v-list-item-title>
                                <v-list-item-subtitle>{{ item.delivery.releasedOn | toShortDateAndTime }}</v-list-item-subtitle>
                            </v-list-item-content>
                        </v-list-item>
                        <v-list-item v-if="item.delivery != null && !item.delivery.isPickup" dense>
                            <v-list-item-content>
                                <v-list-item-title>Delivered By</v-list-item-title>
                                <v-list-item-subtitle>
                                    <BT-Entity
                                        alternateText="Unknown"
                                        navigation="users"
                                        single
                                        :itemValue="item.delivery.deliveredByUserID"
                                        itemText="userName" />
                                </v-list-item-subtitle>
                            </v-list-item-content>
                        </v-list-item>
                    </v-col>
                  </v-row>

                  <v-row class="my-3" dense style="border-bottom: 1px solid lightgray;">
                      <v-col cols="2">QTY</v-col>
                      <v-col cols="6">DESCRIPTION</v-col>
                      <v-col cols="2">UNIT PRICE</v-col>
                      <v-col cols="2" style="text-align: right;">TOTAL</v-col>
                  </v-row>
                      
                  <v-row no-gutters v-for="(lineItem, index) in item.invoice.lineItems" :key="index" style="font-size: 12px;">
                      <v-col cols="2">{{ lineItem.quantity | toDisplayNumber }}</v-col>
                      <v-col cols="6">{{ lineItem.description }}</v-col>
                      <v-col cols="2">{{ lineItem.unitPrice | toCurrency }}</v-col>
                      <v-col cols="2" style="text-align: right;">{{ lineItem.quantity * lineItem.unitPrice | toCurrency }}</v-col>
                  </v-row>
                      
                      <v-divider style="margin: 10px 0px;" />

                      <v-row dense style="font-size: 14px;">
                          <v-col cols="9" style="text-align: right;">Subtotal:</v-col>
                          <v-col cols="3" style="text-align: right;">{{ item.invoice.subTotal | toCurrency }}</v-col>
                      </v-row>
                      <v-row dense style="font-size: 14px;">
                          <v-col cols="9" style="text-align: right;">GST:</v-col>
                          <v-col cols="3" style="text-align: right;">{{ item.invoice.taxTotal | toCurrency }}</v-col>
                      </v-row>
                      
                      <v-divider style="margin: 10px 0px;" />
                      
                      <v-row>
                          <v-col cols="9" style="text-align: right;">Total:</v-col>
                          <v-col cols="3" style="text-align: right;">
                              <h4>{{ item.invoice.amountTotal | toCurrency }}</h4>
                          </v-col>
                      </v-row>
                    </v-container>
                  </template>
                </BT-Print>
                <!-- <BT-Print
                  hide
                  :onPullSuccessAsync="pullForPOD"
                  :showToggle="showPODToggle">
                  <template v-slot="{ item }">
                    <v-container v-if="item != null" style="font-family: sans-serif;">
                      <v-row style="background-color: #1f5a7c; color: white; padding: 5px;">
                          <v-col cols="12">
                              <v-row>
                                  <h2>{{ item.delivery.seller.companyName }}</h2>
                                  <v-spacer />
                                  <h3>Proof Of Delivery</h3>
                              </v-row>
                          </v-col>
                      </v-row>
                      <v-row>
                        <v-col v-if="item.delivery.seller != null" cols="6">
                            <h4>Supplier</h4>
                            <h5>{{ item.delivery.seller.companyName }}</h5>
                          </v-col>
                          <v-col v-if="item.delivery.courier != null" cols="6">
                            <h4>Courier</h4>
                            <h5>{{ item.delivery.courier.companyName }}</h5>
                            <h5 v-if="$BlitzIt.auth.session.company.primaryEmail != null">Email: {{ $BlitzIt.auth.session.company.primaryEmail }}</h5>
                            <h5 v-if="$BlitzIt.auth.session.company.phoneNumber != null">Phone: {{ $BlitzIt.auth.session.company.phoneNumber }}</h5>
                          </v-col>
                      </v-row>

                      <v-row>
                        <v-col cols="6">
                          <h4>Customer</h4>
                          <h5>{{ item.delivery.buyer.companyName }}</h5>
                          <h5 v-if="item.order != null">Consignment #{{ item.order.consignmentNumber }}</h5>
                        </v-col>
                        <v-col cols="6">
                          <h4>Destination</h4>
                          <h5>{{ item.delivery.destinationLocation.streetNumber }} {{ item.delivery.destinationLocation.streetName }}</h5>
                          <h5>{{ item.delivery.destinationLocation.suburb }} {{ item.delivery.destinationLocation.state }} {{ item.delivery.destinationLocation.postcode }}</h5>
                        </v-col>
                      </v-row>

                      <v-row dense style="background-color: #1f5a7c; color: white; margin: 20px 0px 5px 0px; padding: 0px 5px;">
                        <v-col cols="2">Qty</v-col>
                        <v-col cols="7">Description</v-col>
                        <v-col cols="3">Batchcode</v-col>
                      </v-row>

                      <div v-if="item.order != null">
                        <h5>Items</h5>
                        <v-divider style="margin: 2px 0px;" />
                        <v-row dense v-for="(pkg, index) in item.order.consignmentEntryItems" :key="index"
                            style="font-size: 12px;">
                            <v-col cols="2">{{ pkg.quantity | toDisplayNumber }}</v-col>
                            <v-col cols="7">{{ pkg.product.productName }}</v-col>
                            <v-col v-if="pkg.batch != null" cols="3">{{ pkg.batch.batchcode }}</v-col>
                        </v-row>
                      </div>

                      <h5>Packages</h5>
                      <v-divider style="margin: 2px 0px;" />
                      <v-row dense v-for="(pkg, index) in item.delivery.packagedItems" :key="index"
                          style="font-size: 12px;">
                          <v-col cols="2">{{ pkg.quantity | toDisplayNumber }}</v-col>
                          <v-col cols="10">{{ pkg.measurementStandard }}</v-col>
                      </v-row>

                      <v-row dense style="background-color: #1f5a7c; color: white; margin: 20px 0px 5px 0px; padding: 0px 5px;">
                        <v-col cols="12">Proof</v-col>
                      </v-row>

                      <v-row dense>
                        <v-col cols="6">
                          <BT-Entity
                            navigation="users" 
                            :itemValue="item.delivery.deliveredByUserID"
                            itemText="userName">
                            <template v-slot:prepend>Driver: </template>
                          </BT-Entity>
                        </v-col>
                        <v-col cols="6">Signer: {{ item.delivery.receiverName }}</v-col>
                      </v-row>

                      <v-row dense style="margin: 10px 0px;">
                        <v-col cols="6">Delivery Temp: {{ item.delivery.tempOnDelivery }}</v-col>
                        <v-col cols="6">Delivered On: {{ item.delivery.deliveredOn | toShortDateAndTime }}</v-col>
                      </v-row>
                      
                      <BT-Signature
                        v-if="item.delivery.deliveredOn != null"
                        color="black"
                        :value="item.delivery.proofOfDelivery"
                        backgroundColor="white" />

                  </v-container>
                  </template>
                </BT-Print> -->
            
              </v-list-item-action>
            </v-list-item>
          </template>
        </v-toolbar>

        <v-card v-if="mode == 'journey' && journey != null && destinationLocation == null" tile>
          <v-card class="ma-0 pa-0 primary" dark tile>
            <v-row dense>
              <v-col cols="4" class="d-flex flex-column align-center justify-center">
                <v-icon small>mdi-weight-kilogram</v-icon>
                <span class="text-caption">{{ journey.lastWeight | toDisplayNumber }}</span>
              </v-col>
              <v-col cols="4" class="d-flex flex-column align-center justify-center">
                <v-icon small>mdi-cube</v-icon>
                <span class="text-caption">{{ journey.lastPackages | toDisplayNumber }}</span>
              </v-col>
              <v-col cols="4" class="d-flex flex-column align-center justify-center">
                <v-icon small>mdi-truck</v-icon>
                <span class="text-caption">{{ journey.destinationCount | toDisplayNumber }}</span>
              </v-col>
            </v-row>
          </v-card>
          
          <v-tabs-items v-if="!showODO" v-model="commenceStage" touchless class="my-auto transparent" style="width: 100%">
            <v-tab-item>
              <v-subheader>Destinations
                <v-spacer />
                <v-btn v-if="isResorted"
                    class="primary"
                    dark
                    @click="saveDestinationOrder(journey)">
                    <v-icon left>mdi-content-save</v-icon>Save Route</v-btn>
                <v-btn v-else-if="journey.startedOn == null"
                  class="primary"
                  @click="commenceStage = 1"
                  dark
                  :disabled="!journey.isReady">Start Journey</v-btn>
                <BT-Btn v-else-if="journey != null && journey.startedOn != null && journey.endedOn == null"
                    class="primary"
                    @click="commenceFinishJourney"
                    confirm
                    confirmText="Are you sure you want to finish the journey now?"
                    dark
                    :disabled="!canFinishJourney"
                    inline
                    :small="false"
                    label="Finish" />
              </v-subheader>
              <Journey-Destinations v-if="mode == 'journey'"
                :journey="journey"
                @change="selectDestinations"
                :refreshToggle="refreshDestinationsListToggle"
                @resorted="isResorted = $BlitzIt.tracker.isChanged(journey)"
                style="height: calc(100vh - 140px)" />
            </v-tab-item>
            <v-tab-item>
              <v-card no-gutters class="primary" dark>
                <v-subheader dark>
                  <v-btn
                    class="mr-1"
                    icon
                    title="Back"
                    @click="commenceStage = 0">
                    <v-icon>mdi-arrow-left</v-icon>
                  </v-btn>
                  Odometre Reading</v-subheader>
                <BT-Numpad
                    @ok="commenceJourney"
                    buttonClass="transparent"
                    buttonHeight="50"
                    dark />
              </v-card>
            </v-tab-item>
          </v-tabs-items>
          <v-card v-else class="primary" dark>
            <v-tabs-items v-model="finishStage" touchless class="my-auto transparent px-2" style="width: 100%">
              <v-tab-item class="ma-0 pa-0">
                
                <div class="my-2">{{ undelivered.length | toDisplayNumber }} {{ undelivered.length == 1 ? 'delivery has not' : 'deliveries have not' }} been done yet.</div>
                
                <v-list style="height: calc(100vh - 232px);" class="overflow-y-auto primary lighten-1 ma-0 pa-0" dark>
                  <v-list-item v-for="(m, mInd) in undelivered" :key="mInd">
                    <v-list-item-content>
                      <v-list-item-title>{{ m.buyer.companyName }}</v-list-item-title>
                      <v-list-item-subtitle>{{ m.destinationLocation | toLocationLine }}</v-list-item-subtitle>
                    </v-list-item-content>
                  </v-list-item>
                </v-list>

                <div class="my-2">Finish journey anyway?</div>

                <v-row dense>
                  <v-col cols="6">
                    <v-btn block class="primary lighten-1" @click="cancelFinishJourney">No</v-btn>
                  </v-col>
                  <v-col cols="6">
                    <v-btn block class="primary lighten-1" @click="finishStage += 1">Yes</v-btn>
                  </v-col>
                </v-row>
              </v-tab-item>
              <v-tab-item>
                <v-container no-gutters>
                  <v-subheader dark>Odometre Reading</v-subheader>
                  <BT-Numpad
                      @ok="finishJourney"
                      buttonClass="transparent"
                      buttonHeight="50"
                      dark />
                </v-container>
              </v-tab-item>
            </v-tabs-items>
          </v-card>
        </v-card>
        <v-slide-x-transition hide-on-leave>
          <v-container v-if="!showODO && isPerformStage && destinationLocation != null && (isLengthyArray(pFilteredMovements) || isLengthyArray(dFilteredMovements))"
            class="ma-0 pa-0">
            <v-slide-y-transition group hide-on-leave>
              <v-tabs v-if="isLengthyArray(dFilteredMovements) && isLengthyArray(pFilteredMovements)" v-model="performStage" fixed-tabs key="2" background-color="primary lighten-1" dark icons-and-text>
                <v-tab >
                  {{ dFilteredMovements.length }} Dropoff{{ dFilteredMovements.length != 1 ? 's' : '' }}
                  <v-icon >mdi-map-marker-down</v-icon>
                </v-tab>
                <v-tab >
                  {{ pFilteredMovements.length }} Pickup{{ pFilteredMovements.length != 1 ? 's' : '' }}
                  <v-icon >mdi-map-marker-up</v-icon>
                </v-tab>
              </v-tabs>

              <v-tabs v-model="pickupStage" fixed-tabs key="2.1" background-color="primary lighten-1" dark icons-and-text>
                <v-tab >
                  Order
                  <v-icon >mdi-file-document-outline</v-icon>
                </v-tab>
                <v-tab >
                  {{ performStage == 1 ? 'Stock' : 'Returns' }}
                  <v-icon >{{ performStage == 1 ? 'mdi-cube-outline' : 'mdi-refresh-circle' }}</v-icon>
                </v-tab>
                <v-tab >
                  Packages
                  <v-icon>mdi-package-variant</v-icon>
                </v-tab>
                <!-- <v-tab>
                  Release
                  <v-icon >mdi-clipboard-arrow-right-outline</v-icon>
                </v-tab> -->
              </v-tabs>

              <v-tabs-items v-model="performStage" key="3">
                <v-tab-item>
                  <v-alert
                    v-if="dropoffNotReleased"
                    class="primary lighten-1 ma-1"
                    dark>
                    *Some Dropoffs not released yet
                  </v-alert>
                  <v-tabs-items v-else v-model="pickupStage">
                    <v-tab-item>
                      <BT-List
                        dense
                        :items="dFilteredConsignmentOrderItems"
                        showAnyway
                        :style="deliverListStyle">
                        <template v-slot:top>
                          <v-alert
                              class="primary lighten-1 ma-0 py-0"
                              dark
                              tile
                              key="2">
                            <BT-Entity
                              v-if="destinationLocation != null && destinationLocation.id != null"
                              @posted="() => refreshNote(destinationLocation.id)"
                              :findItem="item => { if (item != null && item.length > 0) { return item[0] } else { return null } }"
                              ignoreID
                              :itemValue="destinationLocation.id"
                              navigation="location-notes"
                              :onNew="() => { return { locationID: destinationLocation.id, msg: null } }"
                              :params="{ locationID: destinationLocation.id }"
                              :single="false">
                              <template v-slot="{ item, save, isChanged }">
                                <v-textarea
                                  auto-grow
                                  hide-details
                                  placeholder="Create instructions for this location."
                                  rows="1"
                                  v-model="item.msg">
                                  <template #append>
                                    <v-slide-x-transition>
                                      <v-btn v-if="isChanged" icon @click="save" class="ma-0 mx-1" small>
                                        <v-icon>mdi-content-save</v-icon>
                                      </v-btn>
                                    </v-slide-x-transition>
                                  </template>
                                </v-textarea>
                              </template>
                            </BT-Entity>
                          </v-alert>
                          <v-alert v-if="destinationLocation != null && destinationLocation.driverInstructions != null"
                            class="primary lighten-1 ma-0 py-0"
                            dark
                            tile
                            key="1">
                            <v-divider class="my-1" />
                            <v-textarea
                              auto-grow
                              disabled
                              hide-details
                              label="Customer Instructions"
                              rows="1"
                              v-model="destinationLocation.driverInstructions" />
                          </v-alert>
                        </template>
                        <template v-slot:listItem="{ item, index }">
                          <v-list-group v-if="item.consignmentEntryItems != null && item.consignmentEntryItems.length > 1"
                              :key="index"
                              :append-icon="null"
                              no-action>
                            <template v-slot:activator>
                              <v-list-item-avatar>
                                <v-img :src="productLogoURL(item.product != null ? item.product : undefined)">
                                  <template v-slot:placeholder>
                                      <v-icon class="accent--text">mdi-cube-outline</v-icon>
                                  </template>
                                </v-img>
                              </v-list-item-avatar>
                              <v-list-item-content>
                                <v-list-item-title>{{ item.product.productName }}</v-list-item-title>
                                <v-list-item-subtitle>{{ item.product.abbreviation }}</v-list-item-subtitle>
                              </v-list-item-content>
                              <v-list-item-action class="d-flex align-center">
                                <BT-Increment-Quantity-Edit
                                  v-model="item.totalDelivered"
                                  :goal="item.quantity"
                                  inline
                                  :isEditing="false"
                                  :measurements="measurements"
                                  :productID="item.productID" />
                                <v-icon>mdi-chevron-down</v-icon>
                              </v-list-item-action>
                            </template>
                            <BT-List
                              dense
                              :items="item.consignmentEntryItems">
                              <template v-slot="{ item }">
                                <v-list-item-avatar>
                                  <v-btn small @click="deliverCItem(item)" class="transparent">
                                    <v-icon v-if="item.quantityDelivered == item.quantity" class="success--text">mdi-check</v-icon>
                                    <v-icon v-else-if="item.quantityDelivered > 0" class="warning--text">mdi-check</v-icon>
                                    <v-icon v-else class="error--text">mdi-close</v-icon>
                                  </v-btn>
                                </v-list-item-avatar>
                                <v-list-item-content>
                                  <v-list-item-subtitle v-if="item.batch != null"><strong>{{ item.batch.batchcode }}</strong></v-list-item-subtitle>
                                  <v-list-item-subtitle v-if="item.batch != null">DOM: {{ item.batch.dom | toShortDate }} | EXP: {{ item.batch.exp | toShortDate }}</v-list-item-subtitle>
                                </v-list-item-content>
                                <v-list-item-action class="d-flex align-center">
                                  <BT-Increment-Quantity-Edit
                                    v-model="item.quantityDelivered"
                                    @change="() => { quantityDeliveredChanged(item) }"
                                    :goal="item.quantity"
                                    inline
                                    :measurements="measurements"
                                    :productID="item.productID" />
                                  <v-btn small @click="deliverCItem(item)" class="transparent">
                                    <v-icon v-if="item.quantityDelivered == item.quantity" class="success--text">mdi-check</v-icon>
                                    <v-icon v-else-if="item.quantityDelivered > 0" class="warning--text">mdi-check</v-icon>
                                    <v-icon v-else class="error--text">mdi-close</v-icon>
                                  </v-btn>
                                </v-list-item-action>
                              </template>
                            </BT-List>
                          </v-list-group>
                          <v-list-item v-else class="mx-0 px-2" dense>
                            <v-list-item-avatar>
                              <v-img :src="productLogoURL(item.product != null ? item.product : undefined)">
                                  <template v-slot:placeholder>
                                      <v-icon class="accent--text">mdi-cube-outline</v-icon>
                                  </template>
                              </v-img>
                            </v-list-item-avatar>
                            <v-list-item-content v-if="item.consignmentEntryItems != null && item.consignmentEntryItems.length == 1">
                              <v-list-item-title>{{ item.product.productName }}</v-list-item-title>
                              <v-list-item-subtitle>
                                <strong>{{ item.product.abbreviation }}</strong>
                                <span v-if="item.consignmentEntryItems[0].batch != null"> | {{ item.consignmentEntryItems[0].batch.batchcode }}</span>
                              </v-list-item-subtitle>
                              <v-list-item-subtitle v-if="item.consignmentEntryItems[0].batch != null">DOM: {{ item.consignmentEntryItems[0].batch.dom | toShortDate }} | EXP: {{ item.consignmentEntryItems[0].batch.exp | toShortDate }}</v-list-item-subtitle>
                            </v-list-item-content>
                            <v-list-item-content v-else>
                              <v-list-item-title>{{ item.product.productName }}</v-list-item-title>
                              <v-list-item-subtitle>
                                <strong>{{ item.product.abbreviation }}</strong> - No Stock Available
                              </v-list-item-subtitle>
                            </v-list-item-content>
                            <v-list-item-action v-if="item.consignmentEntryItems != null && item.consignmentEntryItems.length == 1" class="d-flex align-center">
                              <BT-Increment-Quantity-Edit
                                v-model="item.consignmentEntryItems[0].quantityDelivered"
                                @change="() => { quantityDeliveredChanged(item.consignmentEntryItems[0]) }"
                                :displayDifference="false"
                                :goal="item.quantity"
                                inline
                                :measurements="measurements"
                                :productID="item.consignmentEntryItems[0].productID" />
                              <v-btn small @click="deliverCItem(item.consignmentEntryItems[0])" class="transparent">
                                <v-icon v-if="item.consignmentEntryItems[0].quantityDelivered == item.consignmentEntryItems[0].quantity" class="success--text">mdi-check</v-icon>
                                <v-icon v-else-if="item.consignmentEntryItems[0].quantityDelivered > 0" class="warning--text">mdi-check</v-icon>
                                <v-icon v-else class="error--text">mdi-close</v-icon>
                              </v-btn>
                            </v-list-item-action>
                            <v-list-item-action v-else class="d-flex align-center">
                              <BT-Increment-Quantity-Edit
                                :goal="item.quantity"
                                inline
                                :measurements="measurements"
                                :productID="item.productID" />
                                <v-btn v-if="$canView('stock-items')"
                                  @click="editStock(item)"
                                  small
                                  class="primary"
                                  dark>Fix</v-btn>
                            </v-list-item-action>
                          </v-list-item>
                        </template>
                        <template #bottom>
                          <v-list-item
                            v-if="$canView('stock-items')"
                            @click="addProduct">
                            <v-list-item-avatar>
                              <v-icon size="45">mdi-plus</v-icon>
                            </v-list-item-avatar>
                            <v-list-item-content>
                              <v-list-item-title>Create New Line</v-list-item-title>
                            </v-list-item-content>
                          </v-list-item>
                          <v-divider class="my-1" />
                        </template>
                      </BT-List>
                      <v-toolbar class="primary lighten-1" dark dense>
                          <v-row no-gutters>
                            <v-col v-if="$canView('stock-items')" cols="3">
                              <!-- <v-btn block large text>
                                <v-icon left>mdi-note-text</v-icon>Instruct
                              </v-btn> -->
                              <!-- <v-btn block large text @click="addProduct" >
                                <v-icon left>mdi-plus</v-icon>Line
                              </v-btn> -->
                            </v-col>
                            <v-col cols="6" class="d-flex justify-center align-center">
                              <BT-Popup
                                v-if="performStage === 0 && dFilteredMovements.length == 1"
                                block
                                :buttonClass="dFilteredMovements[0].driverNotes != null ? 'success--text' : ''"
                                header="Notes"
                                icon="mdi-message-text"
                                text="Note">
                                <template>
                                  <v-text-field
                                    clearable
                                    height="100"
                                    v-model="dFilteredMovements[0].driverNotes" />
                                </template>
                              </BT-Popup>
                              
                            </v-col>
                            <v-col cols="3">
                              <v-btn block @click.stop="deliverAll" large text><v-icon left>mdi-check-all</v-icon>All</v-btn>
                            </v-col>
                          </v-row>
                        </v-toolbar>
                    </v-tab-item>
                    <v-tab-item>
                      <!-- <div class="text-center">(Press and hold to increment quickly)</div> -->
                      <BT-List
                        dense
                        :items="dFilteredConsignmentReturnableItems"
                        :style="listStyle">
                        <template v-slot="{ item }">
                          <v-list-item-content>
                            <v-list-item-title>{{ item.product.productName }}</v-list-item-title>
                            <v-list-item-subtitle><strong>{{ item.product.abbreviation }}</strong></v-list-item-subtitle>
                          </v-list-item-content>
                          <v-list-item-action>
                            <!-- <BT-Num
                              v-model="item.quantity"
                              buttonClass="primary lighten-1 white--text"
                              small
                              @input="() => { updateReturnableQuantity(item) }" /> -->
                            <BT-Number-Edit
                              v-model="item.quantity"
                              @input="() => { updateReturnableQuantity(item) }"
                              :width="175" />
                            <!-- <BT-Increment-Quantity-Edit
                              v-model="item.quantity"
                              @change="() => { updateReturnableQuantity(item) }"
                              :measurements="measurements"
                              :productID="item.productID" /> -->
                          </v-list-item-action>
                        </template>
                      </BT-List>
                    </v-tab-item>
                    <v-tab-item>
                      <BT-List
                        dense
                        :items="dFilteredConsignmentPackages"
                        :style="listStyle">
                        <template v-slot="{ item }">
                          <v-list-item-content>
                            <v-list-item-title>
                              <BT-Entity
                                navigation="measurements"
                                :itemValue="item.measurementID"
                                itemText="measurementName">
                                <template v-slot:alternate>{{ item.measurementStandard }}</template>
                              </BT-Entity>
                            </v-list-item-title>
                            <v-list-item-subtitle>
                              <BT-Entity
                                navigation="measurements"
                                :itemValue="item.measurementID">
                                <template v-slot="{ item }">{{ item | toMeasurementLine }}</template>
                                <template v-slot:alternate>{{ item | toMeasurementLine }}</template>
                              </BT-Entity>
                            </v-list-item-subtitle>
                          </v-list-item-content>
                          <v-list-item-action>{{ item.quantity | toDisplayNumber }}</v-list-item-action>
                        </template>
                      </BT-List>
                    </v-tab-item>
                    <!-- <v-tab-item>
                      <BT-List :items="dFilteredReleasePackages">
                        <template v-slot="{ item }">
                          <v-list-item-content>
                            <v-list-item-title>
                              <span v-if="item.measurementStandard != null">{{ item.measurementStandard }}</span>
                              <BT-Entity
                                v-else-if="item.measurementID != null"
                                navigation="measurements"
                                :itemValue="item.measurementID"
                                itemText="measurementStandard"
                                single />
                            </v-list-item-title>
                            <v-list-item-subtitle v-if="item.measurementStandard != null">{{ item | toMeasurementString }}</v-list-item-subtitle>
                          </v-list-item-content>
                          <v-list-item-action>
                              <BT-Increment-Quantity-Edit
                                v-model="item.quantityDelivered"
                                :goal="item.quantityReleased"
                                inline
                                :isEditing="canEditReleases" />
                            </v-list-item-action>
                        </template>
                      </BT-List>
                    </v-tab-item> -->
                  </v-tabs-items>
                </v-tab-item>
                <v-tab-item>
                  <v-tabs-items v-model="pickupStage" :touch="{ left: () => {}, right: () => {} }">
                    <v-tab-item>
                      <BT-List
                        dense
                        :items="pFilteredConsignmentOrderItems"
                        :style="listStyle">
                        <template v-slot:listItem="{ item, index }">
                          <v-list-group :key="index" :append-icon="null" no-action>
                              <template v-slot:activator>
                                <v-list-item-avatar>
                                  <v-img :src="productLogoURL(item.product != null ? item.product : undefined)">
                                      <template v-slot:placeholder>
                                          <v-icon class="accent--text">mdi-cube-outline</v-icon>
                                      </template>
                                  </v-img>
                              </v-list-item-avatar>
                                <v-list-item-content v-if="item.product != null">
                                  <v-list-item-subtitle><strong>{{ item.product.productName }}</strong></v-list-item-subtitle>
                                  <v-list-item-title>{{ item.product.abbreviation }}</v-list-item-title>
                                </v-list-item-content>
                                <v-list-item-action>
                                  <v-list-item-action-text>
                                    <BT-Increment-Quantity-Edit
                                      v-model="item.quantity"
                                      :isEditing="false"
                                      :measurements="measurements"
                                      prefix="On Order:"
                                      :productID="item.productID" />
                                  </v-list-item-action-text>
                                  <v-list-item-action-text>
                                    <BT-Increment-Quantity-Edit
                                      v-model="item.totalCounted"
                                      :goal="item.totalAssigned"
                                      inline
                                      :isEditing="false"
                                      :measurements="measurements"
                                      prefix="Stock:"
                                      :productID="item.productID" />
                                  </v-list-item-action-text>
                                </v-list-item-action>
                              </template>
                            
                              <BT-List :items="item.consignmentEntryItems" dense>
                                <template v-slot="{ item }">
                                  <v-list-item-avatar>
                                      <v-icon v-if="item.quantityCounted == item.quantity" class="success--text" @click="countCItem(item)">mdi-check</v-icon>
                                      <v-icon v-else-if="item.quantityCounted > 0" class="warning--text" @click="countCItem(item)">mdi-check</v-icon>
                                      <v-icon v-else class="error--text" @click="countCItem(item)">mdi-close</v-icon>
                                  </v-list-item-avatar>
                                  <v-list-item-content>
                                    <v-list-item-subtitle v-if="item.batch != null"><strong>{{ item.batch.batchcode }}</strong></v-list-item-subtitle>
                                    <v-list-item-subtitle v-if="item.batch != null">DOM: {{ item.batch.dom | toShortDate }} | EXP: {{ item.batch.exp | toShortDate }}</v-list-item-subtitle>
                                  </v-list-item-content>
                                  <v-list-item-action>
                                    <BT-Increment-Quantity-Edit
                                      v-model="item.quantityCounted"
                                      @change="() => { quantityCountedChanged(item) }"
                                      :goal="item.quantity"
                                      inline
                                      :measurements="measurements"
                                      :productID="item.productID" />
                                  </v-list-item-action>
                                </template>
                              </BT-List>
                          </v-list-group>
                        </template>
                      </BT-List>
                    </v-tab-item>
                    <v-tab-item>
                      <BT-List
                        dense
                        :items="pFilteredConsignmentEntryItems"
                        :style="listStyle">
                        <template v-slot="{ item }">
                          <v-list-item-avatar>
                              <v-img :src="productLogoURL(item.product != null ? item.product : undefined)">
                                  <template v-slot:placeholder>
                                      <v-icon class="accent--text">mdi-cube-outline</v-icon>
                                  </template>
                              </v-img>
                          </v-list-item-avatar>
                          <v-list-item-content>
                            <v-list-item-title>{{ item.product.productName }}</v-list-item-title>
                            <v-list-item-subtitle v-if="item.batch != null">{{ item.batch.batchcode }}</v-list-item-subtitle>
                            <v-list-item-subtitle v-if="item.batch != null">DOM: {{ item.batch.dom | toShortDate }} | EXP: {{ item.batch.exp | toShortDate }}</v-list-item-subtitle>
                          </v-list-item-content>
                          <v-list-item-action class="d-flex align-center">
                            <BT-Increment-Quantity-Edit
                              v-model="item.quantityCounted"
                              :goal="item.quantity"
                              inline
                              :measurements="measurements"
                              :productID="item.productID" />
                            <v-btn small @click="item.quantityCounted = item.quantity" class="transparent">
                              <v-icon v-if="item.quantityCounted == item.quantity" class="success--text">mdi-check</v-icon>
                              <v-icon v-else-if="item.quantityCounted > 0" class="warning--text">mdi-check</v-icon>
                              <v-icon v-else class="error--text">mdi-close</v-icon>
                            </v-btn>
                          </v-list-item-action>
                        </template>
                      </BT-List>
                    </v-tab-item>
                    <v-tab-item>
                      <BT-List
                        dense
                        :items="pFilteredConsignmentPackages"
                        :style="listStyle">
                        <template v-slot="{ item }">
                          <v-list-item-content>
                            <v-list-item-title>
                              <BT-Entity
                                navigation="measurements"
                                :itemValue="item.measurementID"
                                itemText="measurementName">
                                <template v-slot:alternate>{{ item.measurementStandard }}</template>
                              </BT-Entity>
                            </v-list-item-title>
                            <v-list-item-subtitle>
                              <BT-Entity
                                navigation="measurements"
                                :itemValue="item.measurementID">
                                <template v-slot="{ item }">{{ item | toMeasurementLine }}</template>
                                <template v-slot:alternate>{{ item | toMeasurementLine }}</template>
                              </BT-Entity>
                            </v-list-item-subtitle>
                          </v-list-item-content>
                          <v-list-item-action>{{ item.quantity | toDisplayNumber }}</v-list-item-action>
                        </template>
                      </BT-List>
                    </v-tab-item>
                    <!-- <v-tab-item>
                      <BT-List :items="pFilteredReleasePackages" showAnyway>
                        <template v-slot="{ item }">
                          <v-list-item-content>
                            <v-list-item-title>
                              <span v-if="item.measurementStandard != null">{{ item.measurementStandard }}</span>
                              <BT-Entity
                                v-else-if="item.measurementID != null"
                                navigation="measurements"
                                :itemValue="item.measurementID"
                                itemText="measurementStandard"
                                single />
                            </v-list-item-title>
                            <v-list-item-subtitle v-if="item.measurementStandard != null">{{ item | toMeasurementString }}</v-list-item-subtitle>
                            <v-list-item-subtitle>
                              <span v-if="pFilteredMovements.some(x => x.id == item.releaseID)">
                                <v-icon small left>mdi-map-marker-down</v-icon>{{ pFilteredMovements.find(x => x.id == item.releaseID).destinationLocation.companyAccount.companyName }}
                              </span>
                            </v-list-item-subtitle>
                          </v-list-item-content>
                            <v-list-item-action>
                              <BT-Increment-Quantity-Edit
                                v-model="item.quantityReleased"
                                :goal="item.quantityPacked"
                                inline
                                :isEditing="canEditReleases" />
                            </v-list-item-action>
                        </template>
                        <template v-slot:bottom>
                          <v-list-item v-if="canEditReleases && pFilteredMovements.length == 1" key="new" dense class="pa-0 mt-4">
                            <v-list-item-content class="pa-0">
                              <v-row>
                                <v-col cols="6">
                                  <BT-Select-Dialog
                                    navigation="measurements"
                                    block
                                    buttonClass="secondary"
                                    text="Package"
                                    return-object
                                    :onFilter="filterMeasurements"
                                    @change="addReleasePackage">
                                    <template slot-scope="{ item }">
                                      <v-list-item-content>
                                        <v-list-item-title>{{ item.measurementName }}</v-list-item-title>
                                        <v-list-item-subtitle>{{ item | toMeasurementLine }}</v-list-item-subtitle>
                                      </v-list-item-content>
                                    </template>
                                  </BT-Select-Dialog>
                                </v-col>
                                <v-col cols="6">
                                  <BT-Dialog
                                      block
                                      :getOnOpen="() => { return { id: null, measurementID: null, measurementStandard: null, code: null, height: null, width: null, length: null, weight: null, volume: null } }"
                                      :show="showDialog"
                                      label="Custom Package"
                                      text="Custom"
                                      buttonClass="secondary"
                                      @ok="addOrUpdateReleasePackage"
                                      @close="showDialog = false"
                                      max-width="450">
                                    <template slot-scope="{ item }">
                                      
                                      <BT-Select
                                        v-model="item.measurementStandard"
                                        itemText="standard"
                                        itemValue="standard"
                                        label="Measurement Standard"
                                        navigation="measurement-standards" />

                                      <v-text-field
                                          label="Code"
                                          v-model="item.code" />

                                      <div v-if="item.measurementStandard == null">
                                        <v-text-field
                                          label="Height"
                                          suffix="mm"
                                          v-model.number="item.height"
                                          type="number" />
                                        <v-text-field
                                            label="Width"
                                            suffix="mm"
                                            v-model.number="item.width"
                                            type="number" />
                                        <v-text-field
                                            label="Length"
                                            suffix="mm"
                                            v-model.number="item.length"
                                            type="number" />
                                        <v-text-field
                                            label="Weight"
                                            suffix="kg"
                                            v-model.number="item.weight"
                                            type="number" />
                                        <v-text-field
                                            label="Volume"
                                            suffix="ltrs"
                                            v-model.number="item.volume"
                                            type="number" />
                                      </div>
                                    </template>
                                  </BT-Dialog>
                                </v-col>
                              </v-row>
                            </v-list-item-content>
                          </v-list-item>
                        </template>
                      </BT-List>
                    </v-tab-item> -->
                  </v-tabs-items>
                </v-tab-item>
              </v-tabs-items>

              <v-bottom-navigation
                v-if="performStage !== 0 || !dropoffNotReleased"
                class="primary ma-0 pa-0"
                dark
                dense
                flat
                grow
                key="3.1">
                <BT-Popup
                  v-if="performStage === 0"
                  @close="applyTemp"
                  button
                  header="Temperature"
                  icon="mdi-thermometer"
                  large
                  persistent
                  :showToggle="showTemperature"> <!--&& canViewStockConsignments-->
                  <template>
                    <BT-Number-Edit
                      v-model.number="temp"
                      :increment="0.5"
                      large
                      prependIcon="mdi-thermometer"
                      single-line />
                  </template>
                </BT-Popup>

                <BT-Camera-Overlay
                  v-if="performStage === 0 && dFilteredMovements.length == 1"
                  button
                  icon="mdi-camera"
                  fullscreen
                  large
                  :small="false"
                  @ok="savePhotoPOD" />

                <BT-Signature-Overlay
                  v-if="performStage == 1 && canEditReleases && isMyDestination"
                  @close="stopSigning"
                  @open="isSigning=true"
                  block
                  buttonClass="primary"
                  color="white"
                  dark
                  :disabled="!isReleasable"
                  fullscreen
                  icon=""
                  large
                  :small="false"
                  @ok="saveRelease"
                  :text="isReleasable ? 'Sign Release' : 'Released'"
                  transparent />
                
                <BT-Signature-Overlay
                  v-if="performStage === 0"
                  @close="stopSigning"
                  @open="isSigning=true"
                  buttonClass="primary"
                  color="white"
                  dark
                  fullscreen
                  large
                  showSignerName
                  :small="false"
                  @ok="proveDelivery"
                  :onDoOpenAsync="doSignAsync"
                  :getOnOpenAsync="getDeliverySignAsync"
                  transparent />
                
                <v-btn
                  v-if="performStage === 0 && dFilteredMovements.length == 1 && dFilteredMovements[0].proofOfDelivery != null && dFilteredMovements[0].deliveredOn == null"
                  class="primary"
                  dark
                  large
                  @click="saveDelivery">
                  <v-icon large>mdi-content-save</v-icon>
                </v-btn>

                <BT-Dialog
                  @close="isInProcess = false"
                  @ok="approveChanges"
                  cancelText="Cancel"
                  hideButton
                  :item="discrepancies"
                  :label="discrepancies.length + ' Discrepanc' + (discrepancies.length == 1 ? 'y' : 'ies')"
                  okText="Approve"
                  :showToggle="discrepanciesToggle">
                  <template v-slot="{ item }">
                    <BT-List :items="item">
                      <template v-slot="{ item }">
                        <v-list-item-content>
                          <v-list-item-title><span v-if="item.isNew" class="mr-2">*Added*</span>{{ item.product.abbreviation }}</v-list-item-title>
                          <v-list-item-subtitle>{{ item.product.productName }}</v-list-item-subtitle>
                        </v-list-item-content>
                        <v-list-item-action>
                          <BT-Increment-Quantity-Edit
                            v-model="item.quantity"
                            :isEditing="false"
                            :measurements="measurements" />
                        </v-list-item-action>
                      </template>
                    </BT-List>
                  </template>
                </BT-Dialog>

              </v-bottom-navigation>

            </v-slide-y-transition>
          </v-container>
        </v-slide-x-transition>
        
        <Stock-Assign-Sidebar
          v-model="currentStockConsignment"
          :allConsignmentOrderItems="allConsignmentOrderItems"
          :allConsignmentEntryItems="allConsignmentEntryItems"
          :filteredConsignmentOrderItems="dFilteredConsignmentOrderItems"
          :filteredConsignmentEntryItems="dFilteredConsignmentEntryItems"
          :consignment="currentStockConsignment"
          :productID="currentProductID"
          :showToggle="showStockSidebarToggle" />

        <v-card v-if="showODO" class="primary py-1" dark tile>
          
        </v-card>

        <v-overlay :value="loadingMsg != null" z-index="3" class="text-center">
          <v-progress-circular indeterminate size="64" />
          <p>{{ loadingMsg }}</p>
        </v-overlay>
        <BT-Snack v-model="msg" :bottom="false" top />
      </v-card>
    </v-slide-x-transition>
  </div>
</template>

<script>
import { firstBy } from 'thenby';
import { getGoogleMapsURL, getImageData, packagesMatch, packageMatchesMeasurement } from '~helpers';

export default {
  name: "Driver-Hub",
  components: {
    // JourneyDestinationList: () => import("~home/journeys/Journey-Destination-List.vue"),
    JourneyDestinations: () => import('~home/journeys/Journey-Destinations.vue'),
    BTSignatureOverlay: () => import("~components/BT-Signature-Overlay.vue"),
    BTSignature: () => import("~components/BT-Signature.vue"),
    BTCameraOverlay: () => import('~components/BT-Camera-Overlay.vue'),
    BTDialog: () => import('~components/BT-Dialog.vue'),
    BTIncrementQuantityEdit: () => import('~components/BT-Increment-Quantity-Edit.vue'),
    BTNumpad: () => import('~components/BT-Numpad.vue'),
    BTPrint: () => import('~components/BT-Print.vue'),
    // BTSelectDialog: () => import('~components/BT-Select-Dialog.vue'),
    BTSelectListDialog: () => import('~components/BT-Select-List-Dialog.vue'),
    BTSkinMenu: () => import('~components/BT-Skin-Menu.vue'),
    StockAssignSidebar: () => import('~home/stock-consignments/Stock-Assign-Sidebar.vue')
  },
  data: function () {
    return {
      allConsignmentIDs: [],
      allConsignmentEntryItems: [],
      allConsignmentOrderItems: [],
      allConsignmentReturnableItems: [],
      allConsignmentPackages: [],
      allMovementConsignmentIDs: [], //[{ key, [] }]
      allReleasePackages: [],
      allReturnableProducts: [],
      allStockConsignments: [],
      allCourierConsignments: [],
      approveDeliveryChanges: false,
      breakdownToggle: false,

      commenceStage: 0,
      currentStockConsignment: null,
      currentProductID: null,

      currentTemp: null,
      destinationLocation: null,
      destinationStage: null, //pickup, dropoff,
      dFilteredConsignmentEntryItems: [],
      dFilteredConsignmentReturnableItems: [],
      dFilteredConsignmentOrderItems: [],
      dFilteredConsignmentPackages: [],
      dFilteredMovements: [],
      dFilteredReleasePackages: [],
      dFilteredStockConsignments: [],
      dFilteredCourierConsignments: [],
      discrepancies: [],
      discrepanciesToggle: false,
      displayToggle: false,
      drawer: false,
      driverJourneysOnly: true,
      driverNotes: null,
      finishStage: 0, //for finish a journey
      isLoaded: false,
      isResorted: false,
      isSigning: false,
      journey: null,
      journeyID: null,
      journeysLoaded: false,
      journeyRefreshToggle: false,
      loadingMsg: null,
      measurements: [],
      mode: null,
      msg: null,
      nextToggle: false,
      performStage: null,
      pFilteredConsignmentEntryItems: [],
      pFilteredConsignmentOrderItems: [],
      pFilteredConsignmentPackages: [],
      pFilteredMovements: [],
      pFilteredReleasePackages: [],
      pFilteredStockConsignments: [],
      pFilteredCourierConsignments: [],

      pickupID: null,
      pickup: null,

      pickupStage: null,
      previousToggle: false,
      printHTML: null,
      refreshDestinationsListToggle: false,
      showPODToggle: false,
      showPrintToggle: false,
      showStockSidebarToggle: false,
      showAddress: false,
      showDialog: false,
      showODO: false,
      showTemperature: false,
      temp: null,
      undelivered: null, //for the finish journey process
    };
  },
  async mounted() {
    this.mode = this.$route.query.mode === 'pickup' ? 'pickup' : 'journey';
    var id = this.$route.params['id'] || this.$route.query['id'];
    
    if (id != null) {
      if (this.mode == 'journey') {
        this.journeyID = id;
      }
      else {
        this.pickupID = id;
      }
    }

    this.isLoaded = true;

    if (this.journeyID != null) {
      await this.pullJourney();
    }
    else if (this.pickupID != null) {
      await this.pullPickupConsignments();
    }
  },
  computed: {
    listStyle() {
      return `height: calc(100vh - ${this.showAddress ? '251' : '176'}px)`
    },
    deliverListStyle() {
      let a = 176
      if (this.showAddress) { a += 75 }
      if (this.performStage == 0 && this.pickupStage == 0) { a += 48 }
      return `height: calc(100vh - ${a.toString()}px)`
    },
    canEditStockConsignments() {
      return true;
      // return this.$BlitzIt.auth.canEdit('stock-consignments');
    },
    canEditCourierConsignments() {
      return true;
      // return this.$BlitzIt.auth.canEdit('customer-consignments');
    },
    canEditReleases() {
      return true;
      // return this.$BlitzIt.auth.canEdit('releases');
    },
    canFinishJourney() {
      return this.journey != null && this.journey.startedOn != null && this.journey.endedOn == null;
    },
    // canViewStockConsignments() {
    //   return this.$BlitzIt.auth.canView('stock-consignments');
    // },
    // canViewCourierConsignments() {
    //   return this.$BlitzIt.auth.canView('customer-consignments');
    // },
    dropoffNotReleased() {
      return this.mode == 'journey' && this.isLengthyArray(this.dFilteredMovements) && this.dFilteredMovements.some(y => y.proofOfRelease == null);
    },
    isFinishStage() {
      return this.mode == 'journey' && this.journey != null && this.journey.isReady && this.journey.endedOn != null
    },
    isMyDestination() {
      return this.destinationLocation != null && this.destinationLocation.companyAccountID == this.myCompanyID;
    },
    isPerformStage() {
      return (this.mode == 'journey' && this.journey != null && this.journey.isReady && this.journey.startedOn != null && this.journey.endedOn == null) ||
        (this.mode == 'pickup' && this.pickup != null);
    },
    isReleasable() {
      return this.isLengthyArray(this.pFilteredMovements) && !this.pFilteredMovements.every(y => y.proofOfRelease != null);
    },
    isRequireTemp() {
      return this.isLengthyArray(this.dFilteredConsignmentOrderItems) && this.dFilteredConsignmentOrderItems.some(y => y.product.isTemperatureSensitive);
    },
    isReviewStage() {
      return this.mode == 'journey' && this.journey != null && this.journey.isReady && this.journey.startedOn == null
    },
    myCompanyID() {
      return this.companyAccountID();
    }, 
    printOptions() {
      var list = [{ display: 'Proof Of Delivery', value: 'pod' }];
      if (this.isLengthyArray(this.dFilteredStockConsignments) && this.dFilteredStockConsignments.length == 1) {
        list.push({ display: 'Invoice', value: 'inv' });
      }
      return list;
    },
    title() {
      if (this.destinationLocation == null) {
        if (this.journey != null) {
          return this.journey.journeyName;
        }
        else {
          return 'Journeys';
        }
      }
      
      if (this.companyAccountID() == this.destinationLocation.companyAccountID) {
        return this.destinationLocation.locationName;
      }
      else {
        return this.destinationLocation.companyAccount.companyName;
      }
    }
  },
  methods: {
    tryNavBack() {
      if (this.journeyID != null || this.pickupID != null) {
        if (this.destinationLocation != null) {
          this.destinationLocation = null;
          //this.showAddress = false;
        }
        else {
          this.resetData();
        }
      }
      else {
        //router nav back
        this.$BlitzIt.navigation.navBack();
      }
    },
    resetData() {
      this.allConsignmentIDs = [];
      this.allConsignmentEntryItems = [];
      this.allConsignmentOrderItems = [];
      this.allConsignmentReturnableItems = [];
      this.allConsignmentPackages = [];
      this.allMovementConsignmentIDs = [];
      this.allReleasePackages = [];
      this.allReturnableProducts = [];
      this.allStockConsignments = [];
      this.approveDeliveryChanges = false;
      // this.breakdownToggle = false;
      this.commenceStage = 0;
      this.currentStockConsignment = null;
      this.currentProductID = null;
      this.currentTemp = null;
      this.destinationLocation = null;
      this.destinationStage = null;
      this.dFilteredConsignmentEntryItems = [];
      this.dFilteredConsignmentReturnableItems = [];
      this.dFilteredConsignmentOrderItems = [];
      this.dFilteredConsignmentPackages = [];
      this.dFilteredMovements = [];
      this.dFilteredReleasePackages = [];
      this.dFilteredStockConsignments = [];
      this.dFilteredCourierConsignments = [];
      this.discrepancies = [];
      // this.discrepanciesToggle = false;
      // this.displayToggle = false;
      this.drawer = false;
      this.driverNotes = null;
      this.finishStage = 0;
      this.isResorted = false;
      this.isSigning = false;
      this.journey = null;
      this.journeyID = null;
      this.performStage = null;
      this.pFilteredConsignmentEntryItems = [];
      this.pFilteredConsignmentOrderItems = [];
      this.pFilteredConsignmentPackages = [];
      this.pFilteredMovements = [];
      this.pFilteredReleasePackages = [];
      this.pFilteredStockConsignments = [];
      this.pFilteredCourierConsignments = [];
      this.pickupID = null;
      this.pickup = null;
      this.pickupStage = null;
      this.printHTML = null;
      this.showODO = false;
      this.showTemperature = false;
      this.temp = null;
      this.undelivered = null
    },
    maybeShowMore(journeys) {
      if (!this.journeysLoaded && this.driverJourneysOnly && !this.isLengthyArray(journeys)) {
        //show all
        this.driverJourneysOnly = false
        this.journeyRefreshToggle = !this.journeyRefreshToggle
      }

      this.journeysLoaded = true
    },
    async addProduct() {
      if (this.isArrayOfLength(this.dFilteredStockConsignments, 1)) {
        var existingIDs = this.dFilteredConsignmentOrderItems.map(y => y.productID);
        var newProduct = await this.$selectItem({
          navigation: 'products',
          itemText: 'productName',
          onFilter: list => list.filter(x => !existingIDs.some(c => c == x.id)),
          params: { filterBy: 'Sold' }
        });

        if (newProduct != null) {
          this.allConsignmentOrderItems.push({
            consignmentID: this.dFilteredStockConsignments[0].id,
            id: null,
            productID: newProduct.id,
            product: newProduct,
            quantity: 0
          });

          this.dFilteredConsignmentOrderItems.push({
            productID: newProduct.id,
            product: newProduct,
            quantity: 0,
            totalDelivered: 0,
            consignmentEntryItems: []
          })
        }


      }
    },
    async addOrUpdateMovements(movSets) { //[{ key: movement, value: conIDs }]
      if (this.isLengthyArray(movSets)) {
        //remove excess consignmentIDs
        movSets.forEach(movSet => {
          var existingSet = this.allMovementConsignmentIDs.find(x => x.key == movSet.key.id);
          if (existingSet != null) {
            var excessConIDs = existingSet.value.filter(y => !movSet.value.some(id => id == y));
            excessConIDs.forEach(conID => {
              this.removeConsignment(conID);
            })
          }
        })

        var refreshMovIDs = movSets.flatMap(x => x.value);
        var excessIDs = refreshMovIDs;
        var iter = 0;

        movSets.forEach(x => {
          if (!this.allMovementConsignmentIDs.some(y => y.key == x.key.id)) {
            this.allMovementConsignmentIDs.push({ key: x.key.id, value: x.value });
          }

          //remove
          this.allReleasePackages = this.allReleasePackages.filter(x => x.releaseID != x.key.id);
          
          //add
          x.key.packagedItems.forEach(pItem => {
            this.allReleasePackages.push(Object.assign({}, pItem, { releaseID: x.key.id }));
          })
        })

        this.allConsignmentIDs = [...new Set(this.allConsignmentIDs, excessIDs)];

        //if (this.canViewCourierConsignments) {
          try {
            if (this.isLengthyArray(excessIDs)) {
              //pull in sets of 20
              do {
                  var conIDs = excessIDs.slice(0, excessIDs.length > 20 ? 20 : excessIDs.length);
                  iter += conIDs.length;
                  this.loadingMsg = `${iter} of ${refreshMovIDs.length} courier consignments`;
                  var cResults = this.copyDeep(await this.$BlitzIt.store.getAll('customer-consignments', { ids: conIDs.toString(), includeDetails: true }, true));
                  
                  cResults.forEach(consignment => {
                      this.addOrUpdateCourierConsignment(consignment);
                  })

                  excessIDs = excessIDs.filter(x => !conIDs.some(y => y == x));
              } while (this.isLengthyArray(excessIDs))
            }
          }
          catch (err) {
            this.msg = this.extractErrorDescription(err);
          }
          finally {
            this.loadingMsg = null;
          }
        //}

        excessIDs = movSets.flatMap(x => x.value);
        iter = 0;

        //if (this.canViewStockConsignments) {
          try {
            if (this.isLengthyArray(excessIDs)) {
              //pull in sets of 20
              do {
                  var ids = excessIDs.slice(0, excessIDs.length > 20 ? 20 : excessIDs.length);
                  iter += ids.length;
                  this.loadingMsg = `${iter} of ${refreshMovIDs.length} stock consignments`;
                  var results = this.copyDeep(await this.$BlitzIt.store.getAll('stock-consignments', { ids: ids.toString(), includeDetails: true }, true));
                  
                  results.forEach(consignment => {
                      this.addOrUpdateStockConsignment(consignment);
                  })

                  excessIDs = excessIDs.filter(x => !ids.some(y => y == x));
              } while (this.isLengthyArray(excessIDs))
            }
          }
          catch (err) {
          this.msg = this.extractErrorDescription(err);
          }
          finally {
            this.loadingMsg = null;
          }
        //}
      }
    },
    addOrUpdateReleasePackage(customPackage) {
      if (this.pFilteredMovements.length == 1) {
        var releaseID = this.pFilteredMovements[0].id;

        var existing = this.allReleasePackages.find(x => x.releaseID == releaseID && packagesMatch(customPackage, x)); //(customPackage.id == x.measurementID || customPackage.measurementStandard == x.measurementStandard));
        if (existing == null) {
          existing = Object.assign({}, customPackage, { releaseID: releaseID, quantityReleased: 1, quantityPacked: 0 });
          this.allReleasePackages.push(existing);
          this.pFilteredReleasePackages.push(existing);
        }
      }
    },
    addReleasePackage(measurement) {
      if (this.pFilteredMovements.length == 1) {
        var releaseID = this.pFilteredMovements[0].id;

        var existing = this.allReleasePackages.find(x => x.releaseID == releaseID && packageMatchesMeasurement(x, measurement)); //(customPackage.id == x.measurementID || customPackage.measurementStandard == x.measurementStandard));
        if (existing == null) {
          existing = {
            id: null,
            releaseID: releaseID,
            measurementID: measurement.id,
            measurementStandard: measurement.measurementStandard,
            code: null,
            height: measurement.height,
            width: measurement.width,
            length: measurement.length,
            weight: measurement.weight,
            volume: measurement.volume,
            quantityReleased: 1,
            quantityPacked: 0,
          }
          this.allReleasePackages.push(existing);
          this.pFilteredReleasePackages.push(existing);
        }
      }
    },
    addOrUpdateStockConsignment(consignment) {
      var existing = this.allStockConsignments.find(x => x.id == consignment.id);

      if (existing != null) {
        //remove
        this.allStockConsignments = this.allStockConsignments.filter(x => x.id != consignment.id);
        this.allConsignmentOrderItems = this.allConsignmentOrderItems.filter(x => x.consignmentID != consignment.id);
        this.allConsignmentEntryItems = this.allConsignmentEntryItems.filter(x => x.consignmentID != consignment.id);
        this.allConsignmentReturnableItems = this.allConsignmentReturnableItems.filter(x => x.consignmentID != consignment.id);
      }
      
      var parentMovementID = this.allMovementConsignmentIDs.find(x => x.value.some(v => v == consignment.id));

      var parentMovement = null;
      if (parentMovementID != null) {
        if (this.mode == 'journey') {
          parentMovement = this.journey.movements.find(z => z.id == parentMovementID.key);
        }
        else if (this.mode == 'pickup') {
          parentMovement = this.pickup;
        }
      }

      this.allStockConsignments.push(Object.assign({}, consignment, { isChanged: false, isSourceChanged: false }));
    
      consignment.consignmentOrderItems.forEach(oItem => {
        this.allConsignmentOrderItems.push(Object.assign({}, oItem, { consignmentID: consignment.id }));
      })

      consignment.consignmentEntryItems.forEach(cItem => {
        if (cItem.quantity < 0) {
          //assume is returnable item
          this.allConsignmentReturnableItems.push(Object.assign({}, cItem, { consignmentID: consignment.id, originalQuantity: 0 - cItem.quantity, quantity: 0 - cItem.quantity } ));
        }
        else {
          //assume is not returnable item
          var toAdd = Object.assign({}, cItem, { 
            consignmentID: consignment.id, 
            quantityCounted: 0, 
            originalCounted: 0, 
            quantityDelivered: 0,
            //originalDelivered: 0,
            originalQuantity: cItem.quantity 
          } );

          if (parentMovement != null && parentMovement.deliveredOn != null) {
            toAdd.quantityDelivered = cItem.quantity;
            //toAdd.originalDelivered = cItem.quantity;
          }

          this.allConsignmentEntryItems.push(toAdd);
        }
      })

    },
    addOrUpdateCourierConsignment(consignment) {
      var existing = this.allCourierConsignments.find(x => x.id == consignment.id);

      if (existing != null) {
        //remove
        this.allCourierConsignments = this.allCourierConsignments.filter(x => x.id != consignment.id);
        this.allConsignmentPackages = this.allConsignmentPackages.filter(x => x.consignmentID != consignment.id);
      }

      this.allCourierConsignments.push(Object.assign({}, consignment, { isChanged: false, isSourceChanged: false }));
      
      consignment.packagedItems.forEach(pItem => {
        this.allConsignmentPackages.push(Object.assign({}, pItem, { consignmentID: consignment.id }));
      })

    },
    approveChanges() {
      this.approveDeliveryChanges = true;
      
      if (this.isInProcess) {
        this.isInProcess = false;
        this.doSignAsync();
      }
    },
    cancelFinishJourney() {
      this.showODO = false;
    },
    async commenceFinishJourney() {
      if (this.journey != null) {
        // this.drawer = false;
        this.selectDestinations([]);
        this.showODO = true;

        if (this.isLengthyArray(this.journey.movements)) {
          var unsaved = this.journey.movements.filter(z => z.proofOfDelivery != null && z.deliveredOn == null);
          if (unsaved.length > 0) {
            await this.$alert("There are unsaved deliveries.  Please save first.");
            return;
          }

          this.undelivered = this.journey.movements.filter(z => z.proofOfDelivery == null);
          this.finishStage = this.isLengthyArray(this.undelivered) ? 0 : 1;
        }
      }
    },
    async commenceJourney(odo) {
      if (this.journey != null && this.journey.startedOn == null) {
        
        try {
          this.loadingMsg = "Starting Journey";

          if (odo == null || Number.isNaN(odo)) {
            odo = null;
          }

          this.commenceStage = 0;

          var res = await this.$BlitzIt.store.patch('journeys', { id: this.journey.id, rowVersion: this.journey.rowVersion, isStarted: true, startingOdometre: odo });

          this.journey.startedOn = res.startedOn;
          this.journey.startingOdometre = res.startingOdometre;
          this.journey.rowVersion = res.rowVersion;

          await this.pullAllConsignments();

          // this.drawer = true;
          this.selectDestinations([])
        }
        catch (err) {
          this.msg = this.extractErrorDescription(err);
        }
        finally {
          this.loadingMsg = null;
        }
      }
    },
    countCItem(filteredConsignmentEntryItem) {
      filteredConsignmentEntryItem.quantityCounted = filteredConsignmentEntryItem.quantity;
      this.quantityCountedChanged(filteredConsignmentEntryItem);
    },
    deliverAll() {
      this.dFilteredConsignmentOrderItems.forEach(oItem => {
        if (this.isLengthyArray(oItem.consignmentEntryItems)) {
          oItem.consignmentEntryItems.forEach(cItem => {
            cItem.quantityDelivered = cItem.quantity
            this.quantityDeliveredChanged(cItem);
          })
        }
      })
    },
    deliverCItem(filteredConsignmentEntryItem) {
      filteredConsignmentEntryItem.quantityDelivered = filteredConsignmentEntryItem.quantity;
      this.quantityDeliveredChanged(filteredConsignmentEntryItem);
    },
    editStock(item) {
      if (item != null) {
        if (this.isArrayOfLength(this.dFilteredStockConsignments, 1)) {
          this.currentStockConsignment = this.dFilteredStockConsignments[0];
        }
        else {
          this.currentStockConsignment = null;
        }

        this.currentProductID = item.productID;
        this.showStockSidebarToggle = !this.showStockSidebarToggle;
      }
    },
    async applyTemp() {
      this.currentTemp = this.temp;
      this.dFilteredMovements.forEach(z => {
        z.tempOnDelivery = this.currentTemp;
      });

      if (this.isInProcess) {
        this.isInProcess = false;
        this.doSignAsync();
      }
    },
    openInMaps(journey) {
      var url = getGoogleMapsURL(journey);

      if (url == null) {
          this.msg = 'No url to open';
      }
      else {
          // if (!extensionExists()) {
              window.open(url, '_blank');
          // }
          // else {
              //send journey to extension
              // this.emitExtensionEvent('openJourneyInMaps', { journey: journey, url: url, date: this.$BlitzIt.auth.formTZ(journey.dueStartOn, 'ccc dd LLL yyyy') });
          // }
      }
    },
    openTemp() {
      this.isInProcess = true;
      this.showTemperature = !this.showTemperature;
    },
    async getDeliverySignAsync() {
      var delivery = this.dFilteredMovements[0];

      return {
        signature: delivery.proofOfDelivery,
        signerName: delivery.receiverName
      }
    },
    async doSignAsync() {
      if (this.dFilteredMovements.length != 1) {
        this.msg = 'Can only sign 1 delivery at a time';
        return false;
      }

      var delivery = this.dFilteredMovements[0];
      
      if (this.isRequireTemp && delivery.tempOnDelivery == null) {
        this.openTemp();
        return false;
      }

      if (!this.canEditStockConsignments) {
        return true;
      }

      //check for returns
      if (this.canEditStockConsignments && this.pickupStage != 1 && this.isLengthyArray(this.dFilteredConsignmentReturnableItems)) {
        this.pickupStage = 1;
        this.msg = 'Any returns?';
        return false;
      }

      //check for changes and make sure driver approves
      if (!this.approveDeliveryChanges) {
        var discrepancies = this.getDeliveryDiscrepancies();
        if (this.isLengthyArray(discrepancies)) {
          this.discrepancies = discrepancies;
          this.discrepanciesToggle = !this.discrepanciesToggle;
          this.isInProcess = true;
          return false;
        }
      }

      return true;
    },
    filterJourneys(list) {
      list.sort(firstBy(x => x.dueStartOn));
      return list;
    },
    filterMeasurements(list) {
      return list.filter(x => !this.pFilteredReleasePackages.some(r => r.measurementID == x.id || r.measurementStandard == x.measurementStandard));
    },
    async finishJourney(odo) {
      if (this.journey != null) {
        // if (this.isLengthyArray(this.journey.movements)) {
        //   var undelivered = this.journey.movements.filter(z => z.proofOfDelivery == null);
        //   if (undelivered.length > 0) {
        //     if (!await this.$confirm(`There ${undelivered.length == 1 ? 'is' : 'are'} ${undelivered.length} incomplete deliver${undelivered.length == 1 ? 'y' : 'ies'}`)) {
        //       return;
        //     }
        //   }
        //   var unsaved = this.journey.movements.filter(z => z.proofOfDelivery != null && z.deliveredOn == null);
        //   if (unsaved.length > 0) {
        //     await this.$alert("There are unsaved deliveries.  Please save first.");
        //     return;
        //   }
        // }

        try {
          this.loadingMsg = 'Finishing Journey';

          if (odo == null || Number.isNaN(odo)) {
            odo = null;
          }

          var patch = {
            id: this.journey.id,
            rowVersion: this.journey.rowVersion,
            isEnded: true,
            endingOdometre: odo
          }

          await this.$BlitzIt.store.patch('journeys', patch);

          this.$router.push({ name: 'home' });
        }
        catch (err) {
          this.msg = this.extractErrorDescription(err);
        }
        finally {
          this.loadingMsg = null;
        }
      }
    },
    getDeliveryDiscrepancies() {
      var discrepancies = []; //{ product, productID, quantity}

      this.dFilteredConsignmentOrderItems.forEach(x => {
        var dif = x.consignmentEntryItems.sum(y => y.quantityDelivered) - x.quantity;
        if (dif != 0) {
          discrepancies.push({
            product: x.product,
            productID: x.productID,
            quantity: dif,
            isNew: false
          })
        }
        else if (x.consignmentEntryItems.some(z => z.isChanged === true)) {
          discrepancies.push({
            product: x.product,
            productID: x.productID,
            quantity: x.quantity,
            isNew: true
          })
        }
      })

      if (this.isLengthyArray(discrepancies)) {
        discrepancies.sort(firstBy(x => x.product.sortNumber));
        return discrepancies;
      }
      else {
        //check for changes in batches delivered
        return [];
      }
    },
    getReturnableDiscrepancies(consignmentID) {
      var discrepancies = []; //{ product, productID, quantity}

      this.dFilteredConsignmentReturnableItems.forEach(x => {
        var rItems = this.allConsignmentReturnableItems.filter(r => r.consignmentID == consignmentID && r.productID == x.productID);
        var dif = x.quantity - rItems.sum(x => x.originalQuantity);
        if (dif != 0) {
          discrepancies.push({
            product: x.product,
            productID: x.productID,
            quantity: dif,
          })
        }
      })

      if (this.isLengthyArray(discrepancies)) {
        discrepancies.sort(firstBy(x => x.product.sortNumber));
        return discrepancies;
      }
      else {
        //check for changes in batches delivered
        return [];
      }
    },
    async printInvoice() {
      if (this.dFilteredStockConsignments.length != 1) {
        this.msg = 'You can only print one invoice at a time';
      }
      else {
        this.showPrintToggle = !this.showPrintToggle;
      }
    },
    async pullForInvoice() {
      if (this.dFilteredStockConsignments.length != 1) {
        this.msg = 'You can only print one invoice at a time';
        return null;
      }

      try {
        this.loadingMsg = 'Retrieving printable invoice';

        var oItems = this.dFilteredConsignmentEntryItems
          .map(x => { return { productID: x.productID, quantity: x.quantityDelivered }})
          .reduce((list, item) => {
            var existing = list.find(x => x.productID == item.productID);
            if (existing != null) {
              existing.quantity += item.quantity;
            }
            else {
              list.push({ productID: item.productID, quantity: item.quantity });
            }
            return list;
          }, []);
        this.dFilteredConsignmentReturnableItems.forEach(rItem => {
          if (rItem.quantity != 0) {
            oItems.push({ productID: rItem.productID, quantity: 0 - rItem.quantity });
          }
        })

        var dropoff = {
          performedOn: this.dFilteredMovements[0].deliveredOn,
          orderItems: oItems,
          id: this.dFilteredStockConsignments[0].id
        };

        var res = await this.$BlitzIt.api.post('customer-orders', dropoff, null, '/ProposedInvoice/ProposedInvoice');

        var uri = this.companyLogoURL(this.dFilteredStockConsignments[0].sellerID);
        var logoImgData = await getImageData(uri, false);

        return {
          invoice: res.data.data,
          consignment: this.dFilteredCourierConsignments[0],
          delivery: this.dFilteredMovements[0],
          logoImgData: logoImgData,
          order: this.dFilteredStockConsignments[0]
        }
      }
      catch (err) {
        this.msg = this.extractErrorDescription(err);
      }
      finally {
        this.loadingMsg = null;
      }
    },
    async pullForPOD() {
      var uri = this.companyLogoURL(this.dFilteredStockConsignments[0].sellerID);
      var logoImgData = await getImageData(uri, false);

      return {
        consignment: this.dFilteredCourierConsignments[0],
        delivery: this.dFilteredMovements[0],
        logoImgData: logoImgData,
        order: this.dFilteredStockConsignments[0]
      }
    },
    async pullAllConsignments() {
      if (this.mode != null && ((this.journey != null && this.journeyID != null) || (this.pickup != null && this.pickupID != null))) {
        var excessIDs = [];
        var iter = 0;

        //if (this.canViewCourierConsignments || this.canViewStockConsignments) {
          try {
            if (this.mode == 'journey') {
              this.loadingMsg = 'Pulling Consignments';
            
              if (this.isLengthyArray(this.journey.movements)) {
                var movIDs = this.journey.movements.map(x => x.id);
                var mRes = await this.$BlitzIt.api.get('deliveries', '/get/GetConsignmentIDs?id=' + movIDs.toString(), null, null);
                this.allMovementConsignmentIDs = mRes.data.data;
              }
              else {
                this.allMovementConsignmentIDs = [];
              }
            }
            else if (this.mode == 'pickup') {
              this.loadingMsg = 'Pulling Pickup';
            
              this.pickup = await this.$BlitzIt.store.get('releases', this.pickupID, null, true, null, null);
              var r = await this.$BlitzIt.api.get('releases', `/GetConsignmentIDs/GetConsignmentIDs/${this.pickupID}`, null, null);
              this.allMovementConsignmentIDs = [{ key: this.pickupID, value: r.data.data }];
            }

            this.allConsignmentIDs = this.allMovementConsignmentIDs.flatMap(x => x.value);
          }
          catch (err) {
            this.msg = this.extractErrorDescription(err);
          }
          finally {
            this.loadingMsg = null;
          }
        //}

        excessIDs = this.allConsignmentIDs;
        iter = 0;

        //if (this.canViewCourierConsignments) {
          try {
            if (this.isLengthyArray(excessIDs)) {
              //pull in sets of 20
              do {
                  var conIDs = excessIDs.slice(0, excessIDs.length > 20 ? 20 : excessIDs.length);
                  iter += conIDs.length;
                  this.loadingMsg = `${iter} of ${this.allConsignmentIDs.length} courier consignments`;
                  var cResults = this.copyDeep(await this.$BlitzIt.store.getAll('customer-consignments', { ids: conIDs.toString(), includeDetails: true }));

                  cResults.forEach(consignment => {
                      this.addOrUpdateCourierConsignment(consignment);
                  })

                  excessIDs = excessIDs.filter(x => !conIDs.some(y => y == x));
              } while (this.isLengthyArray(excessIDs))
            }
          }
          catch (err) {
            this.msg = this.extractErrorDescription(err);
          }
          finally {
            this.loadingMsg = null;
          }
        //}

        excessIDs = this.allConsignmentIDs;
        iter = 0;

        //if (this.canViewStockConsignments) {
          try {
            if (this.isLengthyArray(excessIDs)) {
              //pull in sets of 20
              do {
                  var ids = excessIDs.slice(0, excessIDs.length > 20 ? 20 : excessIDs.length);
                  iter += ids.length;
                  this.loadingMsg = `${iter} of ${this.allConsignmentIDs.length} stock consignments`;
                  var results = this.copyDeep(await this.$BlitzIt.store.getAll('stock-consignments', { ids: ids.toString(), includeDetails: true }, true));

                  results.forEach(consignment => {
                      this.addOrUpdateStockConsignment(consignment);
                  })

                  excessIDs = excessIDs.filter(x => !ids.some(y => y == x));
              } while (this.isLengthyArray(excessIDs))

              try {
                this.loadingMsg = 'Pulling Products';
                this.$forceUpdate();

                this.allReturnableProducts = await this.$BlitzIt.store.getAll('products', { filterBy: 'Returnable' });
              }
              catch (err) {
                this.msg = this.extractErrorDescription(err);
              }
              finally {
                this.loadingMsg = null;
              }
            }
          }
          catch (err) {
          this.msg = this.extractErrorDescription(err);
          }
          finally {
            this.loadingMsg = null;
          }
        //}
        if (this.mode == 'journey') {
          this.selectDestinations([]);
          // this.drawer = true;
        }
        else if (this.mode == 'pickup') {
          this.selectDestinations([{ movement: this.pickup, isPickup: false }])
        }
      }
    },
    quantityDeliveredChanged(filteredConsignmentEntryItem) {
      //whenever deliver happens, it is always limited?
      //update filtered cItem
      var dif = filteredConsignmentEntryItem.quantityDelivered - filteredConsignmentEntryItem.originalDelivered;
      //filteredConsignmentEntryItem.quantityDelivered += dif;

      //update cItems
      var possibleCItems = this.allConsignmentEntryItems.filter(x => this.dFilteredStockConsignments.some(z => z.id == x.consignmentID) && x.batchID == filteredConsignmentEntryItem.batchID && x.productID == filteredConsignmentEntryItem.productID);
      var liveDif = dif;
      possibleCItems.forEach(cItem => {
        if (liveDif > 0) {
          var possToDeliver = cItem.quantity - cItem.quantityDelivered;
          if (possToDeliver >= liveDif) {
            cItem.quantityDelivered += liveDif;
            liveDif = 0;
          }
          else {
            cItem.quantityDelivered += possToDeliver;
            liveDif -= possToDeliver;
          }
        }
        else if (liveDif < 0) {
          if (cItem.quantityDelivered >= (0 - liveDif)) {
            cItem.quantityDelivered += liveDif;
            liveDif = 0;
          }
          else if (cItem.quantityDelivered > 0) {
            liveDif += cItem.quantityDelivered;
            cItem.quantityDelivered = 0;
          }
        }
      })

      if (liveDif != 0 && this.isLengthyArray(possibleCItems)) {
        possibleCItems[0].quantityDelivered += liveDif;
      }

      //update filtered oItem
      var oItem = this.dFilteredConsignmentOrderItems.find(x => x.productID == filteredConsignmentEntryItem.productID);
      if (oItem != null) {
        oItem.totalDelivered += dif;
      }

      filteredConsignmentEntryItem.originalDelivered = filteredConsignmentEntryItem.quantityDelivered;
    },
    quantityCountedChanged(filteredConsignmentEntryItem) {
      //whenever deliver happens, it is always limited?
      //update filtered cItem
      var dif = filteredConsignmentEntryItem.quantityCounted - filteredConsignmentEntryItem.originalCounted;

      //update cItems
      var possibleCItems = this.allConsignmentEntryItems.filter(x => this.pFilteredStockConsignments.some(z => z.id == x.consignmentID) && x.batchID == filteredConsignmentEntryItem.batchID && x.productID == filteredConsignmentEntryItem.productID);
      var liveDif = dif;
      possibleCItems.forEach(cItem => {
        if (liveDif > 0) {
          var possToDeliver = cItem.quantity - cItem.quantityCounted;
          if (possToDeliver >= liveDif) {
            cItem.quantityCounted += liveDif;
            liveDif = 0;
          }
          else {
            cItem.quantityCounted += possToDeliver;
            liveDif -= possToDeliver;
          }
        }
        else if (liveDif < 0) {
          if (cItem.quantityCounted >= (0 - liveDif)) {
            cItem.quantityCounted += liveDif;
            liveDif = 0;
          }
          else if (cItem.quantityCounted > 0) {
            liveDif += cItem.quantityCounted;
            cItem.quantityCounted = 0;
          }
        }
      })

      if (liveDif != 0 && this.isLengthyArray(possibleCItems)) {
        possibleCItems[0].quantityCounted += liveDif;
      }

      //update filtered oItem
      var oItem = this.pFilteredConsignmentOrderItems.find(x => x.productID == filteredConsignmentEntryItem.productID);
      if (oItem != null) {
        oItem.totalCounted += dif;
      }

      filteredConsignmentEntryItem.originalCounted = filteredConsignmentEntryItem.quantityCounted;
    },
    async pullJourney() {
      if (this.journey == null && this.journeyID != null) {
        try {
          this.loadingMsg = 'Loading';
          this.$forceUpdate();

          this.journey = await this.$BlitzIt.store.get('journeys', this.journeyID, { includeDetails: true });

          if (this.journey != null) {
            this.$BlitzIt.tracker.track(this.journey, {
              trackProps: ['movements']
            });
            this.journey.movements.forEach(movement => {
              movement.packagedItems.forEach(pItem => {
                this.allReleasePackages.push(Object.assign({}, pItem, { releaseID: movement.id }));
              })
            })

            this.journey.movements = this.journey.movements.map(m => Object.assign({}, m, { isChanged: false, isSourceChanged: false }));

            if (this.journey.startedOn != null) {
              await this.pullAllConsignments();
            }
          }
        }
        catch (err) {
          this.msg = this.extractErrorDescription(err);
        }
        finally {
          this.loadingMsg = null;
        }
      }
    },
    async pullPickupConsignments() {
      if (this.pickupID != null) {
        try {
          this.loadingMsg = 'Loading';
          
          this.pickup = await this.$BlitzIt.store.get('releases', this.pickupID, { includeDetails: true });

          this.pickup = Object.assign({}, this.pickup, { isChanged: false, isSourceChanged: false });

          this.pickup.packagedItems.forEach(pItem => {
            this.allReleasePackages.push(Object.assign({}, pItem, { releaseID: this.pickup.id }));
          })

          // this.destinationLocation = this.pickup.departureLocation

          await this.pullAllConsignments();
        }
        catch (err) {
          this.msg = this.extractErrorDescription(err);
        }
        finally {
          this.loadingMsg = null;
        }
      }
    },
    async refreshCurrentMovement() {
      if (!this.isLengthyArray(this.pFilteredMovements) && !this.isLengthyArray(this.dFilteredMovements)) {
        return;
      }

      try {
        var ids = this.pFilteredMovements.map(x => x.id).concat(this.dFilteredMovements.map(y => y.id));

        ids = [...new Set(ids)];
        this.loadingMsg = `Refreshing ${ids.length} Movements`;
        
        var res = await this.$BlitzIt.store.getAll('deliveries', { includeDetails: true, ids: ids.toString() });
        var movementsToUpdate = [];

        res.forEach(mov => {
          if (mov.journeyID == null || mov.journeyID != this.journey.id) {
            //remove
            this.removeMovement(mov.id);
          }
          else {
            movementsToUpdate.push(mov);
          }
        })

        if (this.isLengthyArray(movementsToUpdate)) {
          var updateIDs = movementsToUpdate.map(x => x.id);

          var conIDRes = await this.$BlitzIt.api.get('deliveries', '/get/GetConsignmentIDs?id=' + updateIDs.toString(), null, null);
          var movSets = conIDRes.data.data.map(x => { return { key: movementsToUpdate.find(m => m.id == x.key), value: x.value }});
          await this.addOrUpdateMovements(movSets);
        }
      }
      catch (err) {
        this.msg = this.extractErrorDescription(err);
      }
      finally {
        this.loadingMsg = null;
      }
    },
    async refreshJourney() {
      try {
        this.loadingMsg = 'Refreshing Journey';
        var msg = null;
        this.$forceUpdate();

        var journey = await this.$BlitzIt.store.get('journeys', this.journeyID, { includeDetails: true }, true, null);

        if (journey != null) {
          //remove movements
          var excessMovements = this.journey.movements.filter(x => !journey.movements.some(m => m.id == x.id));
          if (this.isLengthyArray(excessMovements)) {
            msg = `${excessMovements.length} removed. `;
          }
          excessMovements.forEach(excessM => {
            this.removeMovement(excessM);
          })

          //add new movements
          var newMovements = journey.movements.filter(x => !this.journey.movements.some(m => m.id == x.id));
          if (this.isLengthyArray(newMovements)) {
            msg = `${msg}${newMovements.length} added. `;
            var updateIDs = newMovements.map(x => x.id);

            var conIDRes = await this.$BlitzIt.api.get('deliveries', '/get/GetConsignmentIDs/' + updateIDs.toString(), null, null);
            var movSets = conIDRes.data.data.map(x => { return { key: newMovements.find(m => m.id == x.key), value: x.value }});
            await this.addOrUpdateMovements(movSets);
          }

          //replace journey
          this.journey = journey;

          this.journey.movements = this.journey.movements.map(m => Object.assign({}, m, { isChanged: false, isSourceChanged: false }));

          this.msg = msg;
        }
      }
      catch (err) {
        this.msg = this.extractErrorDescription(err);
      }
      finally {
        this.loadingMsg = null;
      }
    },
    async refreshNote(locationID) {
      console.log('noted');
      await this.$BlitzIt.store.getAll('location-notes', { locationID: locationID }, true, null, null, false);
    },
    removeConsignment(conID) {
      var cItems = this.allConsignmentEntryItems.filter(x => x.consignmentID == conID);
      var oItems = this.allConsignmentOrderItems.filter(x => x.consignmentID == conID);

      var ind = this.dFilteredStockConsignments.findIndex(x => x.id == conID);
      if (ind >= 0) {
        //remove from filtered lists
        cItems.forEach(cItem => {
          var fItem = this.dFilteredConsignmentEntryItems.find(x => x.productID == cItem.productID && x.batchID == cItem.batchID);
          if (fItem != null) {
            fItem.quantity -= cItem.quantity;
          }
        })

        oItems.forEach(oItem => {
          var eItem = this.dFilteredConsignmentOrderItems.find(x => x.productID == oItem.productID && x.batchID == oItem.batchID);
          if (eItem != null) {
            eItem.quantity -= eItem.quantity;
          }
        })

        this.dFilteredStockConsignments.splice(ind, 1);
      }

      this.dFilteredCourierConsignments = this.dFilteredCourierConsignments.filter(x => x.id != conID);
      
      ind = this.pFilteredStockConsignments.findIndex(x => x.id == conID);
      if (ind >= 0) {
        //remove from filtered lists
        cItems.forEach(cItem => {
          var fItem = this.pFilteredConsignmentEntryItems.find(x => x.productID == cItem.productID && x.batchID == cItem.batchID);
          if (fItem != null) {
            fItem.quantity -= cItem.quantity;
          }
        })

        oItems.forEach(oItem => {
          var eItem = this.pFilteredConsignmentOrderItems.find(x => x.productID == oItem.productID && x.batchID == oItem.batchID);
          if (eItem != null) {
            eItem.quantity -= eItem.quantity;
          }
        })

        this.pFilteredStockConsignments.splice(ind, 1);
      }

      this.pFilteredCourierConsignments = this.pFilteredCourierConsignments.filter(x => x.id != conID);


      this.allConsignmentIDs = this.allConsignmentIDs.filter(x => x != conID);
      this.allConsignmentEntryItems = this.allConsignmentEntryItems.filter(x => x.consignmentID != conID);
      this.allConsignmentOrderItems = this.allConsignmentOrderItems.filter(x => x.consignmentID != conID);
      this.allConsignmentReturnableItems = this.allConsignmentReturnableItems.filter(x => x.consignmentID != conID);
      this.allConsignmentPackages = this.allConsignmentPackages.filter(x => x.consignmentID != conID);
      this.allStockConsignments = this.allStockConsignments.filter(x => x.id != conID);
      this.allCourierConsignments = this.allCourierConsignments.filter(x => x.id != conID);

      this.dFilteredConsignmentReturnableItems = this.dFilteredConsignmentReturnableItems.filter(x => x.consignmentID != conID);
      this.dFilteredConsignmentPackages = this.dFilteredConsignmentPackages.filter(x => x.consignmentID != conID);
      
      this.pFilteredConsignmentPackages = this.pFilteredConsignmentPackages.filter(x => x.consignmentID != conID);
    },
    removeMovement(movID) {
      var movSet = this.allMovementConsignmentIDs.find(x => x.key == movID);
      if (movSet != null) {
        movSet.value.forEach(conID => {
          this.removeConsignment(conID);
        })
      }

      var journeyInd = this.journey.movements.findIndex(x => x.id == movID);
      if (journeyInd >= 0) {
        this.journey.movements.splice(journeyInd, 1);
      }

      this.allMovementConsignmentIDs = this.allMovementConsignmentIDs.filter(x => x.key != movID);
      this.pFilteredMovements = this.pFilteredMovements.filter(x => x.id != movID);
      this.dFilteredMovements = this.dFilteredMovements.filter(x => x.id != movID);
      
      this.allReleasePackages = this.allReleasePackages.filter(x => x.releaseID != movID);
      this.dFilteredReleasePackages = this.dFilteredReleasePackages.filter(x => x.releaseID != movID);
      this.pFilteredReleasePackages = this.pFilteredReleasePackages.filter(x => x.releaseID != movID);
    },
    async proveDelivery(proofOfDeliveryResult) {
      if (proofOfDeliveryResult == null || proofOfDeliveryResult.signature == null) {
        this.msg = 'No signature provided';
        return;
      }

      if (this.dFilteredMovements.length != 1) {
        this.msg = 'Can only sign 1 dropoff at a time';
        return;
      }

      try {
        var proofOfDelivery = proofOfDeliveryResult.signature;
        var signerName = proofOfDeliveryResult.signerName;

        //apply signature to delivery
        var delivery = this.dFilteredMovements[0];

        if (this.mode == 'journey') {
          this.loadingMsg = 'Proving Delivery';

          delivery.proofOfDelivery = proofOfDelivery;
          delivery.receiverName = signerName;

          try {
            this.loadingMsg = 'Getting position';
            var l = await this.$getLocation();
            console.log('location');
            console.log(l);
            if (l != null) {
              delivery.lat = l.lat;
              delivery.lng = l.lng;
            }
          }
          catch (err) {
            console.log(err);
            this.loadingMsg = 'Proving Delivery'
          }
          
        }
        else if (this.mode == 'pickup') {
          this.loadingMsg = 'Proving Pickup';

          delivery.proofOfRelease = proofOfDelivery;
          delivery.receiverName = signerName;
        }
        
        await this.saveDelivery();
      }
      catch (err) {
        this.msg = this.extractErrorDescription(err);
      }
      finally {
        this.loadingMsg = null;
      }
    },
    async saveDelivery() {
      if (this.dFilteredMovements.length != 1) {
        this.msg = 'Can only sign 1 dropoff at a time';
        return;
      }

      if (this.mode == 'journey' && this.dFilteredMovements[0].proofOfDelivery == null) {
        this.msg = 'No Signature';
        return;
      }

      try {
        this.loadingMsg = 'Saving Delivery';

        //save adjusted stock consignment
        if (this.canEditStockConsignments && this.dFilteredStockConsignments.length == 1) {
           //construct stock consignment according to ignore/approve decisions
           var originalConsignment = this.dFilteredStockConsignments[0];
           var allEntries = this.allConsignmentEntryItems
            .filter(x => x.consignmentID == originalConsignment.id)
            .map(x => { return { ...this.copyDeep(x), quantity: 0 } });

           var allReturnableEntries = this.allConsignmentReturnableItems.filter(x => x.consignmentID == originalConsignment.id);

           //create consignment to compare original with
           var newConsignment = { consignmentEntryItems: [] }
           allEntries.forEach(entry => {
              let existing = newConsignment.consignmentEntryItems.find(x => x.batchID == entry.batchID && x.productID == entry.productID);
              if (existing != null) {
                existing.quantity += entry.quantityDelivered;
              }
              else {
                newConsignment.consignmentEntryItems.push({ batchID: entry.batchID, productID: entry.productID, quantity: entry.quantityDelivered });
              }
           })

           allReturnableEntries.forEach(entry => {
              let existing = newConsignment.consignmentEntryItems.find(x => x.productID == entry.productID);
              if (existing != null) {
                existing.quantity -= entry.quantity;
              }
              else {
                newConsignment.consignmentEntryItems.push({ batchID: entry.batchID, productID: entry.productID, quantity: (0 - entry.quantity) });
              }
           })

           //any changes to original consignment?
           let isChanged = originalConsignment.consignmentEntryItems.length !== newConsignment.consignmentEntryItems.length || 
              originalConsignment.consignmentEntryItems.some(originalEntry => {
                let existing = newConsignment.consignmentEntryItems.find(x => x.productID == originalEntry.productID && x.batchID == originalEntry.batchID && x.quantity == originalEntry.quantity);
                return existing == null
              })

          console.log(`is changed? ${isChanged}`);
          console.log(originalConsignment.consignmentEntryItems);
          console.log(newConsignment.consignmentEntryItems);

          if (isChanged) {
            this.loadingMsg = 'Saving Stock Adjustments';
            let stockConsignment = this.copyDeep(originalConsignment);
            let allEntries = this.allConsignmentEntryItems.filter(x => x.consignmentID == stockConsignment.id);
            allEntries.forEach(x => {
              x.quantity = x.quantityDelivered;
            })

            var rItems = this.allConsignmentReturnableItems.filter(x => x.consignmentID == stockConsignment.id);
            rItems.forEach(rItem => {
              var existingEntry = allEntries.find(x => x.productID == rItem.productID);
              if (existingEntry == null) {
                existingEntry = this.copyDeep(rItem);
                allEntries.push(existingEntry);
              }

              existingEntry.quantity = (0 - existingEntry.quantity);
            })

            allEntries = allEntries.filter(x => x.quantity != 0);

            //apply and save changes
            stockConsignment.consignmentOrderItems = null;
            stockConsignment.consignmentEntryItems = allEntries; //.filter(y => y.quantity != 0);

            var conRes = await this.$BlitzIt.store.patch('stock-consignments', stockConsignment);

            originalConsignment.consignmentEntryItems = stockConsignment.consignmentEntryItems;
            originalConsignment.rowVersion = conRes.rowVersion;
           }
           else {
            console.log('not changed');
           }
          // }

          //  var discrepancies = this.getDeliveryDiscrepancies();
          //  var rDiscrepancies = this.getReturnableDiscrepancies(this.dFilteredStockConsignments[0].id);

          //  if (this.isLengthyArray(discrepancies) || this.isLengthyArray(rDiscrepancies)) {
          //   this.loadingMsg = 'Saving Stock Adjustments';

          //   var original = this.dFilteredStockConsignments[0];
          //   var stockConsignment = this.copyDeep(original);
          //   var allEntries = this.allConsignmentEntryItems.filter(x => x.consignmentID == stockConsignment.id);

          //   if (this.isLengthyArray(discrepancies)) {
          //     //update according to what was delivered (assigned?)
          //     allEntries.forEach(x => {
          //       x.quantity = x.quantityDelivered;
          //     })
          //   }
            
          //   //also make any retured item changes
          //   var rItems = this.allConsignmentReturnableItems.filter(x => x.consignmentID == stockConsignment.id);
          //   rItems.forEach(rItem => {
          //     var existingEntry = allEntries.find(x => x.productID == rItem.productID);
          //     if (existingEntry == null) {
          //       existingEntry = this.copyDeep(rItem);
          //       allEntries.push(existingEntry);
          //     }

          //     existingEntry.quantity = (0 - existingEntry.quantity);
          //   })

          //   allEntries = allEntries.filter(x => x.quantity != 0);

          //   //apply and save changes
          //   stockConsignment.consignmentOrderItems = null;
          //   stockConsignment.consignmentEntryItems = allEntries; //.filter(y => y.quantity != 0);

          //   var conRes = await this.$BlitzIt.store.patch('stock-consignments', stockConsignment);

          //   original.consignmentEntryItems = stockConsignment.consignmentEntryItems;
          //   original.rowVersion = conRes.rowVersion;
          //  }
        }

        //save adjusted courier consignment
        if (this.canEditCourierConsignments) {
          console.log('edit courier consignments');
        }

        //save deliveries
        var deliveriesToSave = this.dFilteredMovements; //.filter(y => y.deliveredOn == null);
        var total = deliveriesToSave.length;

        for (let ii = 0; ii < deliveriesToSave.length; ii++) {
          const delivery = deliveriesToSave[ii];
          
          if (delivery.driverNotes != null) {
            delivery.isFlagged = true;
          }

          if (this.mode == 'journey') {
            this.loadingMsg = `Saving ${ii} of ${total} deliveries`;

            var dRes = await this.$BlitzIt.store.patch('deliveries', delivery);

            delivery.rowVersion = dRes.rowVersion;
            delivery.deliveredOn = dRes.deliveredOn;
          }
          else if (this.mode == 'pickup') {
            this.loadingMsg = `Saving ${ii} of ${total} releases`;

            var rRes = await this.$BlitzIt.store.patch('releases', delivery);

            delivery.rowVersion = rRes.rowVersion;
            delivery.releasedOn = rRes.releasedOn;
            delivery.releasedByUserID = rRes.releasedByUserID;
          }
        }
        
        if (this.mode == 'journey') {
          this.selectDestinations([]);
        }
        else if (this.mode == 'pickup') {
          this.$BlitzIt.navigation.navBack();
        }

      }
      catch (err) {
        this.msg = this.extractErrorDescription(err);
      }
      finally {
        this.loadingMsg = null;
      }
    },
    async saveDestinationOrder(journey) {
      if (this.$BlitzIt.tracker.isChanged(journey)) {
        try {
          this.loadingMsg = 'Saving Route';
          var res = await this.$BlitzIt.store.patch('driver-journeys', journey);
          journey.rowVersion = res.rowVersion;
          this.isResorted = false;
          this.$BlitzIt.tracker.reset(journey);
        }
        catch (err) {
          this.msg = this.extractErrorDescription(err);
        }
        finally {
          this.loadingMsg = null;
        }
      }
    },
    async savePhotoPOD(data) {
      if (this.dFilteredMovements != null && this.dFilteredMovements.length == 1) {
        this.loadingMsg = 'Saving Photo';
        try {
          await this.$BlitzIt.api.uploadImage('delivery-image', data, this.dFilteredMovements[0].id, null);
        }
        finally {
          this.loadingMsg = null;
        }
      }
    },
    async saveRelease(proofOfRelease) {
      if (!this.canEditReleases) {
        this.msg = 'No Permission To Sign the Release';
        return;
      }

      if (proofOfRelease == null) {
        this.msg = 'No signature provided';
        return;
      }

      try {
        this.loadingMsg = 'Saving Release';

        var releasesToSave = this.pFilteredMovements.filter(y => y.releasedOn == null);
        
        var total = releasesToSave.length;

        for (let i = 0; i < releasesToSave.length; i++) {
          const release = releasesToSave[i];
          this.loadingMsg = `Saving ${i} of ${total} releases`;

          release.packagedItems = this.allReleasePackages.filter(x => x.releaseID == release.id);
          release.proofOfRelease = proofOfRelease;

          var res = await this.$BlitzIt.store.patch('releases', release);

          release.rowVersion = res.rowVersion;
          release.releasedOn = res.releasedOn;
          release.releasedByUserID = res.releasedByUserID;
        
        }

        // this.drawer = true;
        this.selectDestinations([])
      }
      catch (err) {
        this.msg = this.extractErrorDescription(err);
      }
      finally {
        this.loadingMsg = null;
      }
    },
    selectDestinations(destList) {
      //[{ movement, isPickup }]
      if (this.mode == 'journey' && this.journey?.startedOn == null) {
        return;
      }

      this.isInProcess = false;
      this.approveDeliveryChanges = false;

      if (!this.isLengthyArray(destList)) {
        this.destinationLocation = null;
        return;
      }
      
      var locationIDs = [...new Set(destList.map(x => x.isPickup ? x.movement.departureLocationID : x.movement.destinationLocationID))];
      if (locationIDs.length > 1) {
        this.msg = 'Too many destinations selected';
        return;
      }
      
      this.destinationLocation = destList[0].isPickup ? destList[0].movement.departureLocation : destList[0].movement.destinationLocation;

      //dropoffs
      var dropoffList = destList.filter(x => !x.isPickup);
      var dropoffIDs = dropoffList.map(x => x.movement.id);

      if (this.mode == 'journey') {
        this.dFilteredMovements = this.journey.movements.filter(y => dropoffIDs.some(id => id == y.id));
      }
      else if (this.mode == 'pickup') {
        this.dFilteredMovements = [this.pickup];
      }
      
      var dConsignmentIDs = dropoffList.flatMap(x => {
        var movSet = this.allMovementConsignmentIDs.find(c => c.key == x.movement.id);
        if (movSet != null) {
          return movSet.value;
        }
        else {
          return [];
        }
      })
    
      this.dFilteredStockConsignments = this.allStockConsignments.filter(y => dConsignmentIDs.some(id => id == y.id));
      this.dFilteredCourierConsignments = this.allCourierConsignments.filter(y => dConsignmentIDs.some(id => id == y.id));
      
      var cItems  = this.allConsignmentEntryItems.filter(x => dConsignmentIDs.some(id => x.consignmentID == id));
      var retItems = this.allConsignmentReturnableItems.filter(x => dConsignmentIDs.some(id => x.consignmentID == id));
      var oItems = this.allConsignmentOrderItems.filter(x => dConsignmentIDs.some(id => x.consignmentID == id));
      var pItems = this.allConsignmentPackages.filter(x => dConsignmentIDs.some(id => x.consignmentID == id));
      
      this.dFilteredReleasePackages = this.allReleasePackages.filter(x => dropoffIDs.some(id => x.releaseID == id));

      var products = [...new Set([...oItems.map(x => x.product), ...cItems.map(x => x.product)])];

      var oList = [];
      var cList = [];
      var pList = [];
      var retList = [];

      cItems.forEach(cItem => {
        var existingItem = cList.find(x => x.productID == cItem.productID && x.batchID == cItem.batchID);
        if (existingItem == null) {
          existingItem = {
            id: null,
            productID: cItem.productID,
            product: cItem.product,
            batchID: cItem.batchID,
            batch: cItem.batch,
            originalDelivered: 0,
            quantity: 0,
            quantityDelivered: 0,
          };
          cList.push(existingItem);
        }

        existingItem.quantity += cItem.quantity;
        existingItem.quantityDelivered += cItem.quantityDelivered;
        
        existingItem.originalDelivered = existingItem.quantityDelivered;
      });

      this.dFilteredConsignmentEntryItems = cList.sort(firstBy(x => x.product.sortNumber).thenBy(x => x.batch != null ? x.batch.dom : 0));

      products.forEach(product => {
        var orderItem = oList.find(x => x.productID == product.id);
        if (orderItem == null) {
          var relevantOItems = oItems.filter(y => y.productID == product.id);
          var relevantCItems = cItems.filter(y => y.productID == product.id);
          var relevantFilteredCItems = this.dFilteredConsignmentEntryItems.filter(x => x.productID == product.id);
          
          relevantFilteredCItems.sort(firstBy(x => x.batch != null ? x.batch.dom : 0));
          relevantCItems.sort(firstBy(x => x.batch != null ? x.batch.dom : 0));

          oList.push({
              productID: product.id,
              product: product,
              quantity: relevantOItems.sum(z => z.quantity),
              totalDelivered: relevantCItems.sum(z => z.quantityDelivered),
              consignmentEntryItems: relevantFilteredCItems
          })
        }
      })

      this.dFilteredConsignmentOrderItems = oList.sort(firstBy(x => x.product.sortNumber));
      
      if (dConsignmentIDs.length != 1) {
        this.dFilteredConsignmentReturnableItems = [];
      }
      else {
        var retProducts = [...new Set([...retItems.map(x => x.product), ...this.allReturnableProducts])];

        retProducts.forEach(retProduct => {
          var existingRet = retList.find(y => y.productID == retProduct.id);
          if (existingRet == null) {
            existingRet = {
              id: null,
              productID: retProduct.id,
              product: retProduct,
              quantity: (retItems.filter(y => y.productID == retProduct.id).sum(z => z.quantity))
            }
            retList.push(existingRet);
          }
        })

        this.dFilteredConsignmentReturnableItems = retList.sort(firstBy(x => x.product.sortNumber));
      }

      pItems.forEach(pItem => {
        var pexisting = pList.find(y => y.measurementID == pItem.measurementID && y.measurementStandard == pItem.measurementStandard);
        if (pexisting == null) {
          pexisting = this.copyDeep(pItem);
          pexisting.quantity = 0;
          pList.push(pexisting);
        }
        pexisting.quantity += pItem.quantity;
      })

      this.dFilteredConsignmentPackages = pList.sort(firstBy(x => this.measurementArea(x)).thenBy(y => y.weight || 1).thenBy(y => y.volume || 1));

      this.dFilteredReleasePackages.sort(firstBy(x => this.measurementArea(x)).thenBy(y => y.weight || 1).thenBy(y => y.volume || 1));

      if (this.isLengthyArray(this.dFilteredMovements.filter(y => y.tempOnDelivery != null))) {
        this.currentTemp = Math.max(this.dFilteredMovements.filter(y => y.tempOnDelivery != null).map(y => y.tempOnDelivery));
      }
      else {
        this.currentTemp = null;
      }

      this.temp = this.currentTemp;

      //pickups
      var pickupList = destList.filter(x => x.isPickup);
      var pickupIDs = pickupList.map(x => x.movement.id);

      if (this.mode == 'journey') {
        this.pFilteredMovements = this.journey.movements.filter(y => pickupIDs.some(id => id == y.id));
      }
      else if (this.mode == 'pickup') {
        this.pFilteredMovements = []; //[this.pickup];
      }
      
      var pConsignmentIDs = pickupList.flatMap(x => {
        var movSet = this.allMovementConsignmentIDs.find(c => c.key == x.movement.id);
        if (movSet != null) {
          return movSet.value;
        }
        else {
          return [];
        }
      })

      this.pFilteredStockConsignments = this.allStockConsignments.filter(y => pConsignmentIDs.some(id => id == y.id));
      this.pFilteredCourierConsignments = this.allCourierConsignments.filter(y => pConsignmentIDs.some(id => id == y.id));
      
      cItems  = this.allConsignmentEntryItems.filter(x => x.quantity >= 0 && pConsignmentIDs.some(id => x.consignmentID == id));
      oItems = this.allConsignmentOrderItems.filter(x => pConsignmentIDs.some(id => x.consignmentID == id));
      pItems = this.allConsignmentPackages.filter(x => pConsignmentIDs.some(id => x.consignmentID == id));
      this.pFilteredReleasePackages = this.allReleasePackages.filter(x => pickupIDs.some(id => x.releaseID == id));
      
      products = [...new Set([...oItems.map(x => x.product), ...cItems.map(x => x.product)])];

      oList = [];
      cList = [];
      pList = [];

      cItems.forEach(cItem => {
        var existingItem = cList.find(x => x.productID == cItem.productID && x.batchID == cItem.batchID);
        if (existingItem == null) {
          existingItem = {
            id: null,
            productID: cItem.productID,
            product: cItem.product,
            batchID: cItem.batchID,
            batch: cItem.batch,
            quantity: 0,
            quantityCounted: 0
          };
          cList.push(existingItem);
        }

        existingItem.quantity += cItem.quantity;
        existingItem.quantityCounted += cItem.quantityCounted;

        existingItem.originalCounted = existingItem.quantityCounted;
      });

      this.pFilteredConsignmentEntryItems = cList.sort(firstBy(x => x.product.sortNumber).thenBy(x => x.batch != null ? x.batch.dom : 0));

      products.forEach(product => {
        var orderItem = oList.find(x => x.productID == product.id);
        if (orderItem == null) {
          var relevantOItems = oItems.filter(y => y.productID == product.id);
          var relevantCItems = cItems.filter(y => y.productID == product.id);
          var relevantFilteredCItems = this.pFilteredConsignmentEntryItems.filter(x => x.productID == product.id);
          
          relevantFilteredCItems.sort(firstBy(x => x.batch != null ? x.batch.dom : 0));
          relevantCItems.sort(firstBy(x => x.batch != null ? x.batch.dom : 0));

          oList.push({
              productID: product.id,
              product: product,
              quantity: relevantOItems.sum(z => z.quantity),
              totalCounted: relevantCItems.sum(z => z.quantityCounted),
              totalAssigned: relevantCItems.sum(z => z.quantity),
              consignmentEntryItems: relevantFilteredCItems
          })
        }
      })

      this.pFilteredConsignmentOrderItems = oList.sort(firstBy(x => x.product.sortNumber));
      
      pItems.forEach(pItem => {
        var pexisting = pList.find(y => y.measurementID == pItem.measurementID && y.measurementStandard == pItem.measurementStandard);
        if (pexisting == null) {
          pexisting = this.copyDeep(pItem);
          pexisting.quantity = 0;
          pList.push(pexisting);
        }
        pexisting.quantity += pItem.quantity;
      })

      this.pFilteredConsignmentPackages = pList.sort(firstBy(x => this.measurementArea(x)).thenBy(y => y.weight || 1).thenBy(y => y.volume || 1));

      this.pFilteredReleasePackages.sort(firstBy(x => x.releaseID).thenBy(x => this.measurementArea(x)).thenBy(y => y.weight || 1).thenBy(y => y.volume || 1));

      if (this.isLengthyArray(this.dFilteredMovements)) {
        this.performStage = 0;
      }
      else if (this.isLengthyArray(this.pFilteredMovements)) {
        this.performStage = 1;
      }

      this.pickupStage = 0;

      // if (this.canViewStockConsignments) {
      //   this.pickupStage = 0;
      // }
    },
    async selectJourney(journey) {
      if (journey != null) {
        this.journeyID = journey.id;
        await this.pullJourney();
      }
    },
    async selectPickup(pickup) {
      if (pickup != null) {
        this.pickupID = pickup.id;
        await this.pullPickupConsignments();
      }
    },
    toggleJourneys() {
      this.driverJourneysOnly = !this.driverJourneysOnly;
      this.journeyRefreshToggle = !this.journeyRefreshToggle
    },
    tryToggleAddress() {
      if (this.isPerformStage && this.destinationLocation != null) {
        this.showAddress = !this.showAddress;
      }
    },
    
    async sortJourney() {
      try {
          this.loadingMsg = 'Sorting By History';

          var postData = {
              journeyID: this.journey.journeyTemplateID,
              locationIDs: this.journey.movements.map(x => x.departureLocationID).concat(this.journey.movements.map(y => y.destinationLocationID)),
              startLocationID: this.journey.startLocationID
          };

          var res = await this.$BlitzIt.api.post('journey-archives', postData, null, '/getRouteSuggestion');

          var suggestions = res.data.data;
          var misses = [];

          this.journey.movements.forEach(m => {
              m.departureSortNumber = 0;
              m.arrivalSortNumber = 0;
              m.routeStatus = 'unknown';

              if (m.departureLocationID != this.journey.startLocationID) {
                  var depSug = suggestions.find(x => x.locationID == m.departureLocationID);
              
                  if (depSug != null) {
                      m.departureSortNumber = depSug.sortNumber;
                  }
              }

              var arrSug = suggestions.find(x => x.locationID == m.destinationLocationID && x.sortNumber >= m.departureSortNumber);

              if (arrSug != null) {
                  m.routeStatus = 'found';
                  m.arrivalSortNumber = arrSug.sortNumber;
              }
              else {
                misses.push(m);
                m.arrivalSortNumber = -1; //m.departureSortNumber + 1;
              }
          });

          this.journey.movements.sort(firstBy(x => x.arrivalSortNumber));

          misses.forEach(m => {
              //check for similar suburbs, etc.
              var similarBySuburb = this.journey.movements.filter(f => this.toCompareString(f.destinationLocation.suburb) == this.toCompareString(m.destinationLocation.suburb) && f.arrivalSortNumber > m.departureSortNumber);

              //check for similar postcodes, etc.
              if (!this.isLengthyArray(similarBySuburb)) {
                  similarBySuburb = this.journey.movements.filter(f => this.toCompareString(f.destinationLocation.postcode) == this.toCompareString(m.destinationLocation.postcode) && f.arrivalSortNumber > m.departureSortNumber);
              }

              if (this.isLengthyArray(similarBySuburb)) {
                  similarBySuburb.sort(firstBy(x => x.arrivalSortNumber));

                  m.arrivalSortNumber = similarBySuburb[0].arrivalSortNumber;
              }
          })

          this.journey.movements.sort(firstBy(x => x.arrivalSortNumber));
          
          this.refreshDestinationsListToggle = !this.refreshDestinationsListToggle;
      }
      catch (err) {
          this.msg = this.extractErrorDescription(err);
      }
      finally {
          this.loadingMsg = null;
      }
    },
    stopSigning() {
      this.isSigning = false;
      this.isInProcess = false;
      this.approveDeliveryChanges = false;
    },
    updateReturnableQuantity(filteredReturnableItem) {
      if (this.isLengthyArray(this.dFilteredStockConsignments) && this.dFilteredStockConsignments.length == 1) {
        //proceed
        var consignmentID = this.dFilteredStockConsignments[0].id;

        //update rItem
        var rItem = this.allConsignmentReturnableItems.find(y => y.consignmentID == consignmentID && y.productID == filteredReturnableItem.productID);
        if (rItem == null) {
          rItem = {
            id: null,
            batch: null,
            batchID: null,
            consignmentID: consignmentID,
            originalQuantity: 0,
            product: filteredReturnableItem.product,
            productID: filteredReturnableItem.productID,
            quantity: 0,
          }
          this.allConsignmentReturnableItems.push(rItem);
        }
        
        rItem.quantity = filteredReturnableItem.quantity;
      }
    }
  },
};
</script>